import { AppHttpClient } from 'common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export const TRACKS_BASE_URI = 'tracks';
export class Tracks {
    constructor(http) {
        this.http = http;
    }
    get(id) {
        return this.http.get(`${TRACKS_BASE_URI}/${id}`);
    }
    getRadioRecommendations(id) {
        return this.http.get(`radio/track/${id}`);
    }
    create(payload) {
        return this.http.post(TRACKS_BASE_URI, payload);
    }
    update(id, payload) {
        return this.http.put(`${TRACKS_BASE_URI}/${id}`, payload);
    }
    delete(ids) {
        return this.http.delete(TRACKS_BASE_URI, { ids });
    }
    logPlay(track) {
        return this.http.post(`track/plays/${track.id}/log`);
    }
    loadWaveData(id) {
        return this.http.get(`${TRACKS_BASE_URI}/${id}/wave`);
    }
    loadComments(id, params) {
        return this.http.get(`${TRACKS_BASE_URI}/${id}/comments`, params);
    }
}
Tracks.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Tracks_Factory() { return new Tracks(i0.ɵɵinject(i1.AppHttpClient)); }, token: Tracks, providedIn: "root" });
