import { share } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class Playlists {
    /**
     * Playlists Service Constructor.
     */
    constructor(http) {
        this.http = http;
        /**
         * Fired when tracks are added to a playlist.
         */
        this.addedTracks$ = new EventEmitter();
        /**
         * Fired when tracks are removed from a playlist.
         */
        this.removedTracks$ = new EventEmitter();
        /**
         * Fired when playlist is deleted.
         */
        this.deleted$ = new EventEmitter();
        /**
         * Fired when existing playlist is updated.
         */
        this.updated$ = new EventEmitter();
    }
    /**
     * Get all current user playlists.
     */
    allUserPlaylists(id) {
        return this.http.get(`user/${id}/playlists`);
    }
    all() {
        return this.http.get('playlists');
    }
    get(id) {
        return this.http.get(`playlists/${id}`);
    }
    create(params = {}) {
        return this.http.post('playlists', params);
    }
    update(id, params = {}) {
        const request = this.http.put(`playlists/${id}`, params).pipe(share());
        request.subscribe(playlist => this.updated$.emit(playlist), () => {
        });
        return request;
    }
    delete(ids) {
        const request = this.http.delete('playlists', { ids }).pipe(share());
        request.subscribe(() => this.deleted$.emit(ids), () => {
        });
        return request;
    }
    /**
     * Follow specified playlist with current user.
     */
    follow(id) {
        return this.http.post(`playlists/${id}/follow`);
    }
    /**
     * Unfollow specified playlist with current user.
     */
    unfollow(id) {
        return this.http.post(`playlists/${id}/unfollow`);
    }
    /**
     * Attach specified tracks to playlist.
     */
    addTracks(id, tracks) {
        const ids = tracks.map(track => track.id);
        this.addedTracks$.emit({ id, tracks });
        return this.http.post(`playlists/${id}/tracks/add`, { ids });
    }
    /**
     * Detach specified track from playlist.
     */
    removeTracks(id, tracks) {
        const ids = tracks.map(track => track.id);
        this.removedTracks$.emit({ id, tracks });
        return this.http.post(`playlists/${id}/tracks/remove`, { ids });
    }
    /**
     * Load more tracks for specified playlist.
     */
    loadMoreTracks(id, page = 1) {
        return this.http.get(`playlists/${id}/tracks`, { page });
    }
    /**
     * Change the order of playlist tracks.
     */
    changeTrackOrder(id, tracks) {
        return this.http.post(`playlists/${id}/tracks/order`, { ids: tracks });
    }
}
Playlists.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Playlists_Factory() { return new Playlists(i0.ɵɵinject(i1.AppHttpClient)); }, token: Playlists, providedIn: "root" });
