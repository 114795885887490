import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Search } from '../search.service';
import { of as observableOf } from 'rxjs';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "../search.service";
import * as i2 from "@angular/router";
export class SearchSlideoutPanel {
    constructor(search, router) {
        this.search = search;
        this.router = router;
        this.noResults = false;
        this.searching = false;
        this.isOpen = false;
        this.searchQuery = new FormControl();
        this.bindToSearchQuery();
        this.results = this.getEmptyResultSet();
    }
    close() {
        this.searchQuery.reset();
        this.isOpen = false;
        this.results = this.getEmptyResultSet();
    }
    open() {
        this.isOpen = true;
    }
    clearInput() {
        this.searchQuery.reset();
    }
    goToSearchPage() {
        if (!this.searchQuery.value)
            return;
        this.router.navigate(['/search', this.searchQuery.value]);
    }
    bindToSearchQuery() {
        this.searchQuery.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), switchMap(query => {
            this.searching = true;
            if (!query)
                return observableOf({ results: this.getEmptyResultSet() });
            return this.search.everything(query, { limit: 3 }).pipe(catchError(() => {
                this.searching = false;
                return observableOf({ results: this.getEmptyResultSet() });
            }));
        })).subscribe(response => {
            this.results = response.results;
            this.noResults = !this.responseHasResults(response);
            this.searching = false;
            if (this.searchQuery.value)
                this.open();
        });
    }
    responseHasResults(response) {
        for (const key in response) {
            if (response[key].length)
                return true;
        }
    }
    getEmptyResultSet() {
        return {
            albums: [],
            artists: [],
            tracks: [],
            playlists: [],
            users: [],
        };
    }
}
SearchSlideoutPanel.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchSlideoutPanel_Factory() { return new SearchSlideoutPanel(i0.ɵɵinject(i1.Search), i0.ɵɵinject(i2.Router)); }, token: SearchSlideoutPanel, providedIn: "root" });
