import { Router } from '@angular/router';
import { catchError, mergeMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ChannelService } from '../channel.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../channel.service";
export class ChannelResolverService {
    constructor(router, channels) {
        this.router = router;
        this.channels = channels;
    }
    resolve(route, state) {
        const idOrSlug = +route.params.id || route.params.slug || route.data.id;
        return this.channels.get(idOrSlug).pipe(catchError(() => {
            this.redirectOnResolveFail(route);
            return EMPTY;
        }), mergeMap(response => {
            if (response) {
                return of(response);
            }
            else {
                this.redirectOnResolveFail(route);
                return EMPTY;
            }
        }));
    }
    redirectOnResolveFail(route) {
        const redirectUri = route.data.failRedirectUri;
        if (redirectUri && this.router.url !== redirectUri) {
            return this.router.navigate([redirectUri]);
        }
    }
}
ChannelResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChannelResolverService_Factory() { return new ChannelResolverService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ChannelService)); }, token: ChannelResolverService, providedIn: "root" });
