/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-player-controls.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../player-controls/player-seekbar/player-seekbar.component.ngfactory";
import * as i3 from "../player-controls/player-seekbar/player-seekbar.component";
import * as i4 from "../formatted-duration.service";
import * as i5 from "../player.service";
import * as i6 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "../../artists/artists-links-list/artists-links-list.component.ngfactory";
import * as i9 from "../../artists/artists-links-list/artists-links-list.component";
import * as i10 from "../../web-player-urls.service";
import * as i11 from "../player-controls/main-playback-buttons/main-playback-buttons.component.ngfactory";
import * as i12 from "../player-controls/main-playback-buttons/main-playback-buttons.component";
import * as i13 from "@angular/common";
import * as i14 from "../../../../common/core/ui/custom-menu/custom-menu.component.ngfactory";
import * as i15 from "../../../../common/core/ui/custom-menu/custom-menu.component";
import * as i16 from "../../../../common/core/config/settings.service";
import * as i17 from "../../../../common/auth/current-user";
import * as i18 from "../../../../common/shared/appearance/appearance-listener.service";
import * as i19 from "./mobile-player-controls.component";
import * as i20 from "../../fullscreen-overlay/fullscreen-overlay.service";
var styles_MobilePlayerControlsComponent = [i0.styles];
var RenderType_MobilePlayerControlsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MobilePlayerControlsComponent, data: {} });
export { RenderType_MobilePlayerControlsComponent as RenderType_MobilePlayerControlsComponent };
function View_MobilePlayerControlsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "player-seekbar", [], null, null, null, i2.View_PlayerSeekbarComponent_0, i2.RenderType_PlayerSeekbarComponent)), i1.ɵdid(2, 4374528, null, 0, i3.PlayerSeekbarComponent, [i1.ElementRef, i4.FormattedDuration, i5.Player, i1.NgZone], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "no-style maximize"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.overlay.maximize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "keyboard-arrow-up"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "track-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "track-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "artists-links-list", [["class", "artist-name"]], null, null, null, i8.View_ArtistsLinksListComponent_0, i8.RenderType_ArtistsLinksListComponent)), i1.ɵdid(11, 573440, null, 0, i9.ArtistsLinksListComponent, [i10.WebPlayerUrls], { track: [0, "track"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "main-playback-buttons", [], null, null, null, i11.View_MainPlaybackButtonsComponent_0, i11.RenderType_MainPlaybackButtonsComponent)), i1.ɵdid(13, 49152, null, 0, i12.MainPlaybackButtonsComponent, [i5.Player], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "keyboard-arrow-up"; _ck(_v, 6, 0, currVal_2); var currVal_4 = _co.player.getCuedTrack(); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 6).inline; var currVal_1 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_3 = _co.player.getCuedTrack().name; _ck(_v, 9, 0, currVal_3); }); }
export function View_MobilePlayerControlsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobilePlayerControlsComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "custom-menu", [["class", "nav"], ["position", "mobile-bottom"]], [[2, "hidden", null], [2, "vertical", null], [2, "horizontal", null]], null, null, i14.View_CustomMenuComponent_0, i14.RenderType_CustomMenuComponent)), i1.ɵdid(3, 245760, null, 0, i15.CustomMenuComponent, [i16.Settings, i17.CurrentUser, i1.ChangeDetectorRef, i18.AppearanceListenerService, i1.ChangeDetectorRef], { position: [0, "position"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.player.cued(); _ck(_v, 1, 0, currVal_0); var currVal_4 = "mobile-bottom"; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 3).shouldHide; var currVal_2 = i1.ɵnov(_v, 3).vertical; var currVal_3 = i1.ɵnov(_v, 3).horizontal; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_MobilePlayerControlsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-player-controls", [], null, null, null, View_MobilePlayerControlsComponent_0, RenderType_MobilePlayerControlsComponent)), i1.ɵdid(1, 49152, null, 0, i19.MobilePlayerControlsComponent, [i5.Player, i20.FullscreenOverlay], null, null)], null, null); }
var MobilePlayerControlsComponentNgFactory = i1.ɵccf("mobile-player-controls", i19.MobilePlayerControlsComponent, View_MobilePlayerControlsComponent_Host_0, {}, {}, []);
export { MobilePlayerControlsComponentNgFactory as MobilePlayerControlsComponentNgFactory };
