import { Routes } from '@angular/router';
import { ContactComponent } from '@common/contact/contact.component';
import { AuthGuard } from '@common/guards/auth-guard.service';
const ɵ0 = () => import("./admin/app-admin.module.ngfactory").then(m => m.AppAdminModuleNgFactory), ɵ1 = () => import("./../common/billing/billing.module.ngfactory").then(m => m.BillingModuleNgFactory);
const routes = [
    { path: 'admin', loadChildren: ɵ0, canLoad: [AuthGuard] },
    { path: 'billing', loadChildren: ɵ1 },
    { path: 'contact', component: ContactComponent },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };
