import { Track } from '../../models/Track';
import { PlayerState } from './player-state.service';
import { shuffler } from './shuffler';
import { LocalStorage } from 'common/core/services/local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./player-state.service";
import * as i2 from "../../../common/core/services/local-storage.service";
export class PlayerQueue {
    /**
     * PlayerState Service.
     */
    constructor(state, localStorage) {
        this.state = state;
        this.localStorage = localStorage;
        /**
         * Queue after shuffling.
         */
        this.queue = [];
        /**
         * Original queue before shuffling.
         */
        this.originalQueue = [];
        /**
         * Current queue pointer.
         */
        this.pointer = 0;
    }
    /**
     * Get all queue items.
     */
    getAll() {
        return this.queue;
    }
    /**
     * Check if specified track is currently in queue.
     */
    has(track) {
        return this.queue.findIndex(predicate => {
            return predicate.id === track.id;
        }) >= 0;
    }
    /**
     * Move queue pointer to specified track.
     */
    select(track) {
        if (!track)
            track = new Track();
        this.set(this.queue.findIndex(predicate => {
            return predicate.id === track.id;
        }));
    }
    empty() {
        return this.getTotal() > 0;
    }
    override(params, queuePointer = 0) {
        this.queuedMediaItemId = params.queuedItemId;
        this.queue = params.tracks.slice();
        if (this.state.shuffling)
            this.shuffle(true);
        this.originalQueue = params.tracks.slice();
        this.set(queuePointer);
    }
    append(tracks) {
        this.queue = this.queue.concat(tracks.slice());
        this.originalQueue = this.queue.concat(tracks.slice());
    }
    prepend(tracks) {
        this.queue = this.addTracksAtIndex(this.queue, tracks);
        this.originalQueue = this.addTracksAtIndex(this.originalQueue, tracks);
    }
    addTracksAtIndex(array, tracksToAdd) {
        const tail = array.splice(this.pointer + 1);
        return [...array, ...tracksToAdd, ...tail];
    }
    remove(track) {
        let i = this.queue.findIndex(predicate => predicate === track);
        if (i === -1)
            i = this.queue.findIndex(predicate => predicate.id === track.id);
        this.queue.splice(i, 1);
    }
    /**
     * Shuffle the queue.
     */
    shuffle(keepFirst = false) {
        this.queue = shuffler.shuffle(this.getAll(), keepFirst);
    }
    mediaItemIsQueued(itemId) {
        return this.queuedMediaItemId === itemId;
    }
    /**
     * Restore queue to original (if it has been shuffled)
     */
    restoreOriginal() {
        this.queue = this.originalQueue.slice();
    }
    getFirst() {
        return this.get(0);
    }
    getLast() {
        return this.get(this.getTotal() - 1);
    }
    getTotal() {
        return this.queue.length;
    }
    getNext() {
        return this.get(this.pointer + 1);
    }
    /**
     * Check if current track is the last one in queue.
     */
    isLast() {
        return this.getLast() === this.getCurrent();
    }
    /**
     * Check if current track is the first one in queue.
     */
    isFirst() {
        return this.getFirst() === this.getCurrent();
    }
    getPrevious() {
        return this.get(this.pointer - 1);
    }
    getCurrent() {
        return this.get(this.pointer);
    }
    get(index) {
        return this.queue[index] || null;
    }
    set(index) {
        if (index === -1)
            index = null;
        this.pointer = index;
        this.localStorage.set('player.queue.pointer', index);
    }
}
PlayerQueue.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlayerQueue_Factory() { return new PlayerQueue(i0.ɵɵinject(i1.PlayerState), i0.ɵɵinject(i2.LocalStorage)); }, token: PlayerQueue, providedIn: "root" });
