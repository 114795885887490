import {Injectable} from '@angular/core';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {Track} from '../../models/Track';

@Injectable({
    providedIn: 'root'
})
export class PlayerTracksService {
    constructor(private http: AppHttpClient) {}

    public load(queueId: string, lastTrack?: Track): Promise<Track[]> {
        return new Promise(resolve => {
            return this.http.post<{tracks: Track[]}>('player/tracks', {queueId, lastTrack})
                .subscribe(response => {
                    resolve(response.tracks);
                }, () => resolve([]));
        });
    }
}
