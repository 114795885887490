import { EventEmitter } from '@angular/core';
import { LocalStorage } from 'common/core/services/local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/services/local-storage.service";
export class PlayerState {
    constructor(storage) {
        this.storage = storage;
        this._playing = false;
        this._buffering = false;
        this._muted = false;
        this._repeating = true;
        this._repeatingOne = false;
        this._shuffling = false;
        this.onChange$ = new EventEmitter();
    }
    /**
     * Fired when playback ends (track reaches the end)
     */
    firePlaybackEnded() {
        this.onChange$.emit('PLAYBACK_ENDED');
    }
    /**
     * Fired when playback is stopped (paused and seeked to 0)
     */
    firePlaybackStopped() {
        this.onChange$.emit('PLAYBACK_STOPPED');
    }
    /**
     * Fired when playback strategy is first bootstrapped.
     */
    fireReadyEvent() {
        this.onChange$.emit('PLAYBACK_STRATEGY_READY');
    }
    get playing() {
        return this._playing;
    }
    set playing(value) {
        if (this._playing === value)
            return;
        this._playing = value;
        if (value && this.buffering)
            this.buffering = false;
        this.onChange$.emit('PLAYBACK_' + (value ? 'STARTED' : 'PAUSED'));
    }
    get buffering() {
        return this._buffering;
    }
    set buffering(value) {
        if (this._buffering === value)
            return;
        this._buffering = value;
        if (value && this.playing)
            this.playing = false;
        this.onChange$.emit('BUFFERING_' + (value ? 'STARTED' : 'STOPPED'));
    }
    get muted() {
        return this._muted;
    }
    set muted(value) {
        this._muted = value;
        this.storage.set('player.muted', value);
    }
    get repeating() {
        return this._repeating;
    }
    set repeating(value) {
        this._repeating = value;
        this.storage.set('player.repeating', value);
    }
    get repeatingOne() {
        return this._repeatingOne;
    }
    set repeatingOne(value) {
        this._repeatingOne = value;
        if (value)
            this.repeating = false;
        this.storage.set('player.repeatingOne', value);
    }
    get shuffling() {
        return this._shuffling;
    }
    set shuffling(value) {
        this._shuffling = value;
        this.storage.set('player.shuffling', value);
    }
}
PlayerState.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlayerState_Factory() { return new PlayerState(i0.ɵɵinject(i1.LocalStorage)); }, token: PlayerState, providedIn: "root" });
