import { AppHttpClient } from 'common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class Genres {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    create(params) {
        return this.httpClient.post('genres', params);
    }
    update(id, params) {
        return this.httpClient.put('genres/' + id, params);
    }
    delete(ids) {
        return this.httpClient.delete('genres', { ids });
    }
    get(name, params = {}) {
        return this.httpClient.get(`genres/${name}`, params);
    }
}
Genres.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Genres_Factory() { return new Genres(i0.ɵɵinject(i1.AppHttpClient)); }, token: Genres, providedIn: "root" });
