import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export const CHANNEL_BASE_URI = 'channel';
export class ChannelService {
    constructor(http) {
        this.http = http;
    }
    all(params) {
        return this.http.get(CHANNEL_BASE_URI, params);
    }
    get(id) {
        return this.http.get(`${CHANNEL_BASE_URI}/${id}`);
    }
    create(params) {
        return this.http.post(CHANNEL_BASE_URI, params);
    }
    update(id, params) {
        return this.http.put(`${CHANNEL_BASE_URI}/${id}`, params);
    }
    delete(ids) {
        return this.http.delete(`${CHANNEL_BASE_URI}/${ids}`);
    }
    detachItem(channelId, item) {
        const payload = { item: { id: item.id, model_type: item.model_type } };
        return this.http.post(`${CHANNEL_BASE_URI}/${channelId}/detach-item`, payload);
    }
    attachItem(channelId, item) {
        return this.http.post(`${CHANNEL_BASE_URI}/${channelId}/attach-item`, { item });
    }
    changeOrder(channelId, ids) {
        return this.http.post(`${CHANNEL_BASE_URI}/${channelId}/change-order`, { ids });
    }
}
ChannelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChannelService_Factory() { return new ChannelService(i0.ɵɵinject(i1.AppHttpClient)); }, token: ChannelService, providedIn: "root" });
