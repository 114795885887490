/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./comment-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/media-image/media-image.component.ngfactory";
import * as i3 from "../../../shared/media-image/media-image.component";
import * as i4 from "../../../../../common/core/config/settings.service";
import * as i5 from "@angular/common";
import * as i6 from "./comment-bar.component";
import * as i7 from "../../track-comments.service";
import * as i8 from "../../../../../common/core/ui/overlay-panel/overlay-panel.service";
import * as i9 from "../../../../../common/auth/current-user";
var styles_CommentBarComponent = [i0.styles];
var RenderType_CommentBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommentBarComponent, data: {} });
export { RenderType_CommentBarComponent as RenderType_CommentBarComponent };
function View_CommentBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "comment"]], [[4, "left", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.showContent($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.hideContent() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "media-image", [], null, null, null, i2.View_MediaImageComponent_0, i2.RenderType_MediaImageComponent)), i1.ɵdid(2, 573440, null, 0, i3.MediaImageComponent, [i4.Settings], { media: [0, "media"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.user; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.position + "%"); _ck(_v, 0, 0, currVal_0); }); }
export function View_CommentBarComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { commentMarker: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "comment-track"]], [[2, "marker-active", null]], null, null, null, null)), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CommentBarComponent_1)), i1.ɵdid(4, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, [[1, 0], ["commentMarker", 1]], null, 3, "div", [["class", "comment comment-marker"]], [[2, "hidden", null]], null, null, null, null)), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 1, "media-image", [], null, null, null, i2.View_MediaImageComponent_0, i2.RenderType_MediaImageComponent)), i1.ɵdid(9, 573440, null, 0, i3.MediaImageComponent, [i4.Settings], { media: [0, "media"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.trackComments.waveComments$)); _ck(_v, 4, 0, currVal_1); var currVal_3 = _co.currentUser.getModel(); _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.markerActive$)); _ck(_v, 1, 0, currVal_0); var currVal_2 = !i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.markerActive$)); _ck(_v, 6, 0, currVal_2); }); }
export function View_CommentBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "comment-bar", [["class", "comment-marker-ancestor"]], null, [[null, "tap"], [null, "panstart"], [null, "panleft"], [null, "panright"]], function (_v, en, $event) { var ad = true; if (("tap" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onTap($event) !== false);
        ad = (pd_0 && ad);
    } if (("panstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onPanstart($event) !== false);
        ad = (pd_1 && ad);
    } if (("panleft" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onPanHorizontal($event) !== false);
        ad = (pd_2 && ad);
    } if (("panright" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onPanHorizontal($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, View_CommentBarComponent_0, RenderType_CommentBarComponent)), i1.ɵdid(1, 4374528, null, 0, i6.CommentBarComponent, [i7.TrackCommentsService, i8.OverlayPanel, i1.NgZone, i1.ElementRef, i9.CurrentUser], null, null)], null, null); }
var CommentBarComponentNgFactory = i1.ɵccf("comment-bar", i6.CommentBarComponent, View_CommentBarComponent_Host_0, {}, {}, []);
export { CommentBarComponentNgFactory as CommentBarComponentNgFactory };
