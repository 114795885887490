import * as tslib_1 from "tslib";
import { PlayerQueue } from './player-queue.service';
import { YoutubeStrategy } from './strategies/youtube-strategy.service';
import { PlayerState } from './player-state.service';
import { Settings } from 'common/core/config/settings.service';
import { FullscreenOverlay } from '../fullscreen-overlay/fullscreen-overlay.service';
import { WebPlayerState } from '../web-player-state.service';
import { Html5Strategy } from './strategies/html5-strategy.service';
import { SoundcloudStrategy } from './strategies/soundcloud-strategy.service';
import { TrackPlays } from './track-plays.service';
import { LocalStorage } from 'common/core/services/local-storage.service';
import { BrowserEvents } from 'common/core/services/browser-events.service';
import { Title } from '@angular/platform-browser';
import { PlayerTracksService } from './player-tracks.service';
import { MetaTagsService } from '@common/core/meta/meta-tags.service';
import * as i0 from "@angular/core";
import * as i1 from "./player-queue.service";
import * as i2 from "./strategies/youtube-strategy.service";
import * as i3 from "./strategies/html5-strategy.service";
import * as i4 from "./strategies/soundcloud-strategy.service";
import * as i5 from "../../../common/core/services/local-storage.service";
import * as i6 from "../../../common/core/config/settings.service";
import * as i7 from "./player-state.service";
import * as i8 from "../web-player-state.service";
import * as i9 from "../fullscreen-overlay/fullscreen-overlay.service";
import * as i10 from "../../../common/core/services/browser-events.service";
import * as i11 from "./track-plays.service";
import * as i12 from "@angular/platform-browser";
import * as i13 from "../../../common/core/meta/meta-tags.service";
import * as i14 from "./player-tracks.service";
export class Player {
    constructor(queue, youtube, html5, soundcloud, storage, settings, state, globalState, overlay, browserEvents, trackPlays, title, metaTags, trackLoader) {
        this.queue = queue;
        this.youtube = youtube;
        this.html5 = html5;
        this.soundcloud = soundcloud;
        this.storage = storage;
        this.settings = settings;
        this.state = state;
        this.globalState = globalState;
        this.overlay = overlay;
        this.browserEvents = browserEvents;
        this.trackPlays = trackPlays;
        this.title = title;
        this.metaTags = metaTags;
        this.trackLoader = trackLoader;
        this.subscriptions = [];
        /**
         * Whether playback has been started via user gesture.
         *
         * If true, there's no need to maximize player overlay
         * anymore, because external controls will work properly.
         */
        this.playbackStartedViaGesture = false;
    }
    play() {
        if (!this.ready())
            return;
        const track = this.queue.getCurrent();
        if (!track)
            return this.stop();
        this.setStrategy(track);
        this.maybeMaximizeOverlay();
        this.playbackStrategy.play();
    }
    mediaItemPlaying(queueId) {
        return this.state.playing && this.queue.mediaItemIsQueued(queueId);
    }
    playMediaItem(queueId, tracks, selectTrack) {
        this.cueMediaItem(queueId, tracks, selectTrack).then(() => {
            this.play();
        });
    }
    cueMediaItem(queueId, tracks, selectTrack) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.stop();
            this.state.buffering = true;
            if (!this.queue.mediaItemIsQueued(queueId)) {
                if (!tracks) {
                    tracks = yield this.trackLoader.load(queueId);
                }
                yield this.overrideQueue({
                    tracks: tracks,
                    queuedItemId: queueId
                });
            }
            if (selectTrack) {
                this.queue.select(selectTrack);
            }
        });
    }
    pause() {
        this.playbackStrategy.pause();
    }
    togglePlayback() {
        if (this.isPlaying()) {
            this.pause();
        }
        else {
            this.play();
        }
    }
    ready() {
        return this.playbackStrategy.ready();
    }
    isPlaying() {
        return this.state.playing;
    }
    cued(track) {
        const cued = this.getCuedTrack() && this.getCuedTrack().id;
        if (!track)
            return cued;
        return cued && cued === track.id;
    }
    getState() {
        return this.state;
    }
    getQueue() {
        return this.queue;
    }
    isBuffering() {
        return this.state.buffering;
    }
    isMuted() {
        return this.state.muted;
    }
    getCuedTrack() {
        if (!this.playbackStrategy)
            return null;
        return this.playbackStrategy.getCuedTrack();
    }
    mute() {
        this.playbackStrategy.mute();
        this.state.muted = true;
    }
    unMute() {
        this.playbackStrategy.unMute();
        this.state.muted = false;
    }
    getVolume() {
        return this.volume;
    }
    setVolume(volume) {
        this.volume = volume;
        this.playbackStrategy.setVolume(volume);
        this.storage.set('player.volume', volume);
    }
    stop() {
        if (!this.state.playing)
            return;
        this.playbackStrategy.pause();
        this.seekTo(0);
        this.state.playing = false;
        this.state.firePlaybackStopped();
    }
    /**
     * Get time that has elapsed since playback start.
     */
    getCurrentTime() {
        return this.playbackStrategy.getCurrentTime();
    }
    /**
     * Get total duration of track in seconds.
     */
    getDuration() {
        return this.playbackStrategy.getDuration();
    }
    /**
     * Seek to specified time in track.
     */
    seekTo(time) {
        this.playbackStrategy.seekTo(time);
        return new Promise(resolve => setTimeout(() => resolve(), 50));
    }
    /**
     * Toggle between repeat, repeat one and no repeat modes.
     */
    toggleRepeatMode() {
        if (this.state.repeating) {
            this.state.repeatingOne = true;
        }
        else if (this.state.repeatingOne) {
            this.state.repeatingOne = false;
            this.state.repeating = false;
        }
        else {
            this.state.repeating = true;
        }
    }
    playNext() {
        this.stop();
        let track = this.queue.getCurrent();
        if (this.state.repeating && this.queue.isLast()) {
            track = this.queue.getFirst();
        }
        else if (!this.state.repeatingOne) {
            track = this.queue.getNext();
        }
        if (track) {
            this.queue.select(track);
            this.play();
        }
        else {
            this.state.buffering = false;
            this.stop();
        }
    }
    /**
     * Play previous track in queue based on current repeat setting.
     */
    playPrevious() {
        this.stop();
        let track = this.queue.getCurrent();
        if (this.state.repeating && this.queue.isFirst()) {
            track = this.queue.getLast();
        }
        else if (!this.state.repeatingOne) {
            track = this.queue.getPrevious();
        }
        this.queue.select(track);
        this.play();
    }
    /**
     * Toggle player shuffle mode.
     */
    toggleShuffle() {
        if (this.state.shuffling) {
            this.queue.restoreOriginal();
        }
        else {
            this.queue.shuffle();
        }
        this.state.shuffling = !this.state.shuffling;
    }
    /**
     * Override player queue and cue first track.
     */
    overrideQueue(params, queuePointer = 0) {
        this.putQueueIntoLocalStorage(params.tracks);
        this.queue.override(params, queuePointer);
        return this.cueTrack(this.queue.getCurrent());
    }
    /**
     * Cue specified track for playback.
     */
    cueTrack(track) {
        let promise;
        this.setStrategy(track);
        if (!track || !this.playbackStrategy) {
            promise = new Promise(resolve => resolve());
        }
        else {
            this.queue.select(track);
            promise = this.playbackStrategy.cueTrack(track);
        }
        return promise.then(() => {
            this.state.buffering = false;
        });
    }
    getPlaybackStrategy() {
        return this.activePlaybackStrategy;
    }
    init() {
        this.loadStateFromLocalStorage();
        this.setStrategy(this.queue.getCurrent());
        this.setInitialVolume();
        this.cueTrack(this.queue.getCurrent());
        this.bindToPlaybackStateEvents();
        this.initKeybinds();
    }
    initForEmbed(track) {
        this.setStrategy(track);
        this.setInitialVolume();
        this.cueTrack(track);
    }
    destroy() {
        this.metaTags.staticTitle = null;
        this.playbackStrategy && this.playbackStrategy.destroy();
        this.state.playing = false;
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    }
    putQueueIntoLocalStorage(tracks) {
        if (!tracks)
            return;
        this.storage.set('player.queue', { tracks: tracks.slice(0, 15) });
    }
    setStrategy(track) {
        if (track && track.url) {
            this.playbackStrategy = this.html5;
            this.activePlaybackStrategy = 'html5';
        }
        else if (this.settings.get('audio_search_provider') === 'soundcloud') {
            this.playbackStrategy = this.soundcloud;
            this.activePlaybackStrategy = 'soundcloud';
        }
        else {
            this.playbackStrategy = this.youtube;
            this.activePlaybackStrategy = 'youtube';
        }
        // destroy all except current active playback strategy
        if (this.activePlaybackStrategy !== 'youtube')
            this.youtube.destroy();
        if (this.activePlaybackStrategy !== 'html5')
            this.html5.destroy();
        if (this.activePlaybackStrategy !== 'soundcloud')
            this.soundcloud.destroy();
        return this.playbackStrategy;
    }
    loadStateFromLocalStorage() {
        this.state.muted = this.storage.get('player.muted', false);
        this.state.repeating = this.storage.get('player.repeating', true);
        this.state.repeatingOne = this.storage.get('player.repeatingOne', false);
        this.state.shuffling = this.storage.get('player.shuffling', false);
        const queuePointer = this.storage.get('player.queue.pointer', 0);
        this.queue.override(this.storage.get('player.queue', { tracks: [] }), queuePointer);
    }
    setInitialVolume() {
        let defaultVolume = this.settings.get('player.default_volume', 30);
        defaultVolume = this.storage.get('player.volume', defaultVolume);
        this.setVolume(defaultVolume);
        this.html5.setVolume(defaultVolume);
    }
    /**
     * Maximize fullscreen overlay if we're on mobile,
     * because youtube embed needs to be visible to start
     * playback with external youtube iframe api controls
     */
    maybeMaximizeOverlay() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const shouldOpen = this.settings.get('player.mobile.auto_open_overlay');
            if (this.playbackStartedViaGesture || !shouldOpen || !this.globalState.isMobile)
                return;
            yield this.overlay.maximize();
            this.playbackStartedViaGesture = true;
        });
    }
    bindToPlaybackStateEvents() {
        this.state.onChange$.subscribe((type) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (type === 'PLAYBACK_STARTED') {
                const cuedTrack = this.getCuedTrack();
                if (cuedTrack) {
                    this.trackPlays.increment(cuedTrack);
                    this.metaTags.staticTitle = `${cuedTrack.name} - ${(cuedTrack.artists && cuedTrack.artists[0] ? cuedTrack.artists[0].name : '?')}`;
                }
            }
            else if (type === 'PLAYBACK_ENDED') {
                this.trackPlays.clearPlayedTrack(this.getCuedTrack());
                if (this.queue.isLast() && this.queue.queuedMediaItemId) {
                    this.state.buffering = true;
                    yield this.trackLoader.load(this.queue.queuedMediaItemId, this.queue.getLast()).then(tracks => {
                        this.queue.append(tracks);
                    });
                }
                this.playNext();
            }
            else if (type === 'PLAYBACK_PAUSED') {
                this.metaTags.staticTitle = null;
            }
        }));
    }
    initKeybinds() {
        const sub = this.browserEvents.globalKeyDown$.subscribe((e) => {
            // SPACE - toggle playback
            if (e.keyCode === this.browserEvents.keyCodes.space) {
                this.togglePlayback();
                e.preventDefault();
                // ctrl+right - play next track
            }
            else if (e.ctrlKey && e.keyCode === this.browserEvents.keyCodes.arrowRight) {
                this.playNext();
                e.preventDefault();
            }
            // ctrl+left - play previous track
            else if (e.ctrlKey && e.keyCode === this.browserEvents.keyCodes.arrowLeft) {
                this.playPrevious();
                e.preventDefault();
            }
        });
        this.subscriptions.push(sub);
    }
}
Player.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Player_Factory() { return new Player(i0.ɵɵinject(i1.PlayerQueue), i0.ɵɵinject(i2.YoutubeStrategy), i0.ɵɵinject(i3.Html5Strategy), i0.ɵɵinject(i4.SoundcloudStrategy), i0.ɵɵinject(i5.LocalStorage), i0.ɵɵinject(i6.Settings), i0.ɵɵinject(i7.PlayerState), i0.ɵɵinject(i8.WebPlayerState), i0.ɵɵinject(i9.FullscreenOverlay), i0.ɵɵinject(i10.BrowserEvents), i0.ɵɵinject(i11.TrackPlays), i0.ɵɵinject(i12.Title), i0.ɵɵinject(i13.MetaTagsService), i0.ɵɵinject(i14.PlayerTracksService)); }, token: Player, providedIn: "root" });
