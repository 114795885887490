import { AppHttpClient } from 'common/core/http/app-http-client.service';
import { SearchResponse } from './search-results';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class Search {
    constructor(http) {
        this.http = http;
    }
    everything(query, params) {
        return this.http.get('search/' + encodeURIComponent(query), params);
    }
    videoId(artist, track) {
        const artistName = this.encode(artist), trackName = this.encode(track.name);
        return this.http.get(`search/audio/${track.id}/${artistName}/${trackName}`);
    }
    autocompleteArtists(params) {
        return this.http.get('autocomplete/artists', params);
    }
    autocompleteAlbums(params) {
        return this.http.get('autocomplete/albums', params);
    }
    encode(string) {
        return encodeURIComponent((string || '').replace('/', '-'));
    }
}
Search.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Search_Factory() { return new Search(i0.ɵɵinject(i1.AppHttpClient)); }, token: Search, providedIn: "root" });
