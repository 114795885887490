import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
const BASE_URI = 'notifications';
export class NotificationsService {
    constructor(http) {
        this.http = http;
    }
    markAsRead(ids) {
        return this.http.post(`${BASE_URI}/mark-as-read`, { ids });
    }
    all(params) {
        return this.http.get(BASE_URI, params);
    }
}
NotificationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.ɵɵinject(i1.AppHttpClient)); }, token: NotificationsService, providedIn: "root" });
