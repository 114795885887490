import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ContextMenu} from 'common/core/ui/context-menu/context-menu.service';
import {DOCUMENT} from '@angular/common';
import {Player} from '../player/player.service';
import {PlayerQueue} from '../player/player-queue.service';
import {FullscreenOverlay} from './fullscreen-overlay.service';
import {BrowserEvents} from '@common/core/services/browser-events.service';
import {WebPlayerState} from '../web-player-state.service';
import {WebPlayerImagesService} from '../web-player-images.service';
import {Subscription} from 'rxjs';
import {Track} from '../../models/Track';
import {TrackContextMenuComponent} from '../tracks/track-context-menu/track-context-menu.component';

@Component({
    selector: 'fullscreen-overlay',
    templateUrl: './fullscreen-overlay.component.html',
    styleUrls: ['./fullscreen-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class.maximized]': 'overlay.isMaximized()',
        'class': 'fullscreen-overlay',
    }
})
export class FullscreenOverlayComponent implements OnInit, OnDestroy {
    public subscription: Subscription;

    constructor(
        public player: Player,
        private el: ElementRef,
        public queue: PlayerQueue,
        private contextMenu: ContextMenu,
        public overlay: FullscreenOverlay,
        private browserEvents: BrowserEvents,
        public state: WebPlayerState,
        public wpImages: WebPlayerImagesService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit () {
        this.subscription = this.browserEvents.globalKeyDown$.subscribe(e => {
            // minimize overlay on ESC key press.
            if (e.keyCode === this.browserEvents.keyCodes.escape) {
                this.overlay.minimize();
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.subscription = null;
    }

    /**
     * Get current track in player queue.
     */
    public getCurrent() {
        return this.queue.getCurrent() || new Track();
    }

    /**
     * Get previous track in player queue.
     */
    public getPrevious() {
        return this.queue.getPrevious() || this.getCurrent();
    }

    /**
     * Get next track in player queue.
     */
    public getNext() {
        return this.queue.getNext() || this.getCurrent();
    }

    /**
     * Open track context menu.
     */
    public openTrackContextMenu(track: Track, e: MouseEvent) {
        e.stopPropagation();

        this.contextMenu.open(
            TrackContextMenuComponent,
            e.target,
            {data: {item: track, type: 'track'}}
        );
    }

    /**
     * Exit browser fullscreen mode or minimize the overlay.
     */
    public minimize() {
        if (this.isBrowserFullscreen()) {
            this.exitBrowserFullscreen();
        } else {
            this.overlay.minimize();
        }
    }

    /**
     * Toggle browser fullscreen mode.
     */
    public toggleBrowserFullscreen() {
        const el = this.el.nativeElement;

        if (this.isBrowserFullscreen()) {
            return this.exitBrowserFullscreen();
        }

        if (el.requestFullscreen) {
            el.requestFullscreen();
        } else if (el.msRequestFullscreen) {
            el.msRequestFullscreen();
        } else if (el.mozRequestFullScreen) {
            el.mozRequestFullScreen();
        } else if (el.webkitRequestFullScreen) {
            el.webkitRequestFullScreen();
        }
    }

    /**
     * Exit browser fullscreen mode.
     */
    public exitBrowserFullscreen() {
        if (this.document.exitFullscreen) {
            this.document.exitFullscreen();
        } else if (this.document.webkitExitFullscreen) {
            this.document.webkitExitFullscreen();
        } else if (this.document.mozCancelFullScreen) {
            this.document.mozCancelFullScreen();
        } else if (this.document.msExitFullscreen) {
            this.document.msExitFullscreen();
        }
    }

    /**
     * Check if browser fullscreen mode is active.
     */
    public isBrowserFullscreen() {
        return this.document.fullscreenElement ||
        this.document.webkitFullscreenElement ||
        this.document.mozFullscreenElement ||
        this.document.msFullScreenElement;
    }
}
