/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./comment-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./comment-overlay.component";
import * as i3 from "../../../../../../common/core/ui/overlay-panel/overlay-panel-data";
import * as i4 from "../../../../../../common/core/ui/overlay-panel/overlay-panel-ref";
var styles_CommentOverlayComponent = [i0.styles];
var RenderType_CommentOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommentOverlayComponent, data: {} });
export { RenderType_CommentOverlayComponent as RenderType_CommentOverlayComponent };
export function View_CommentOverlayComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "user"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.comment.user.display_name; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.comment.content; _ck(_v, 3, 0, currVal_1); }); }
export function View_CommentOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "comment-overlay", [], null, null, null, View_CommentOverlayComponent_0, RenderType_CommentOverlayComponent)), i1.ɵdid(1, 114688, null, 0, i2.CommentOverlayComponent, [[2, i3.OVERLAY_PANEL_DATA], i4.OverlayPanelRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommentOverlayComponentNgFactory = i1.ɵccf("comment-overlay", i2.CommentOverlayComponent, View_CommentOverlayComponent_Host_0, {}, {}, []);
export { CommentOverlayComponentNgFactory as CommentOverlayComponentNgFactory };
