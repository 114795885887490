import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {catchError, mergeMap} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import {BackendResponse} from '@common/core/types/backend-response';
import {Channel} from '../channel';
import {ChannelService} from '../channel.service';

@Injectable({
    providedIn: 'root'
})
export class ChannelResolverService implements Resolve<BackendResponse<{channel: Channel}>> {
    constructor(
        private router: Router,
        private channels: ChannelService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): BackendResponse<{channel: Channel}> {
        const idOrSlug = +route.params.id || route.params.slug || route.data.id;
        return this.channels.get(idOrSlug).pipe(
            catchError(() => {
                this.redirectOnResolveFail(route);
                return EMPTY;
            }),
            mergeMap(response => {
                if (response) {
                    return of(response);
                } else {
                    this.redirectOnResolveFail(route);
                    return EMPTY;
                }
            })
        );
    }

    private redirectOnResolveFail(route: ActivatedRouteSnapshot) {
        const redirectUri = route.data.failRedirectUri;
        if (redirectUri && this.router.url !== redirectUri) {
            return this.router.navigate([redirectUri]);
        }
    }
}

