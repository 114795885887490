import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
const BASE_URI = 'repost';
export class RepostsService {
    constructor(http) {
        this.http = http;
    }
    crupdate(media) {
        return this.http.post(`${BASE_URI}`, { repostable_id: media.id, repostable_type: media.model_type });
    }
}
RepostsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RepostsService_Factory() { return new RepostsService(i0.ɵɵinject(i1.AppHttpClient)); }, token: RepostsService, providedIn: "root" });
