import * as i0 from "@angular/core";
export class QueueSidebar {
    constructor() {
        /**
         * Whether queue sidebar is currently visible.
         */
        this.visible = true;
    }
    isVisible() {
        return this.visible;
    }
    show() {
        this.visible = true;
    }
    hide() {
        this.visible = false;
    }
    toggle() {
        this.visible = !this.visible;
    }
}
QueueSidebar.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QueueSidebar_Factory() { return new QueueSidebar(); }, token: QueueSidebar, providedIn: "root" });
