import {NgModule} from '@angular/core';
import {NavSidebarComponent} from './nav-sidebar/nav-sidebar.component';
import {WebPlayerComponent} from './web-player.component';
import {SearchSlideoutPanelComponent} from './search/search-slideout-panel/search-slideout-panel.component';
import {WebPlayerRoutingModule} from './web-player-routing.module';
import {AlbumComponent} from './albums/album/album.component';
import {TrackListComponent} from './tracks/track-list/track-list.component';
import {QueueSidebarComponent} from './queue-sidebar/queue-sidebar.component';
import {FullscreenOverlayComponent} from './fullscreen-overlay/fullscreen-overlay.component';
import {LibraryTrackToggleButtonComponent} from './users/user-library/library-track-toggle-button/library-track-toggle-button.component';
import {LibraryTracksComponent} from './users/user-library/library-tracks/library-tracks.component';
import {LibraryAlbumsComponent} from './users/user-library/library-albums/library-albums.component';
import {LibraryArtistsComponent} from './users/user-library/library-artists/library-artists.component';
import {AlbumItemComponent} from './albums/album-item/album-item.component';
import {ArtistItemComponent} from './artists/artist-item/artist-item.component';
import {ArtistComponent} from './artists/artist/artist.component';
import {AlbumContextMenuComponent} from './albums/album-context-menu/album-context-menu.component';
import {CrupdatePlaylistModalComponent} from './playlists/crupdate-playlist-modal/crupdate-playlist-modal.component';
import {TrackContextMenuComponent} from './tracks/track-context-menu/track-context-menu.component';
import {ArtistContextMenuComponent} from './artists/artist-context-menu/artist-context-menu.component';
import {GenreComponent} from './genres/genre/genre.component';
import {ContextMenuPlaylistPanelComponent} from './context-menu/context-menu-playlist-panel/context-menu-playlist-panel.component';
import {PlaylistComponent} from './playlists/playlist/playlist.component';
import {SearchComponent} from './search/search/search.component';
import {UserItemComponent} from './users/user-item/user-item.component';
import {PlaylistTrackContextMenuComponent} from './playlists/playlist-track-context-menu/playlist-track-context-menu.component';
import {PlaylistContextMenuComponent} from './playlists/playlist-context-menu/playlist-context-menu.component';
import {TrackPageComponent} from './tracks/track-page/track-page.component';
import {RadioPageComponent} from './radio-page/radio-page.component';
import {ShareMediaItemModalComponent} from './context-menu/share-media-item-modal/share-media-item-modal.component';
import {LyricsModalComponent} from './lyrics/lyrics-modal/lyrics-modal.component';
import {UserLibraryComponent} from './users/user-library/user-library.component';
import {LibraryPlaylistsComponent} from './users/user-library/library-playlists/library-playlists.component';
import {
    MatBadgeModule,
    MatChipsModule,
    MatDialogModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule
} from '@angular/material';
import {WEB_PLAYER_CONTEXT_MENUS} from './available-context-menus';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {AVAILABLE_CONTEXT_MENUS} from '@common/core/ui/context-menu/available-context-menus';
import {UiModule} from '@common/core/ui/ui.module';
import {MediaImageModule} from './shared/media-image/media-image.module';
import {UploadImageControlModule} from '@common/shared/form-controls/upload-image-control/upload-image-control.module';
import {UploadsModule} from '@common/uploads/uploads.module';
import {ChannelShowComponent} from './channels/channel-show/channel-show.component';
import {ChannelContentComponent} from './channels/channel-show/channel-content/channel-content.component';
import {PlayHistoryComponent} from './users/user-library/play-history/play-history.component';
import {TrackItemComponent} from './tracks/track-item/track-item.component';
import {GenreItemComponent} from './genres/genre-item/genre-item.component';
import {TruncatedDescriptionComponent} from './tracks/track-page/truncated-description/truncated-description.component';
import {NotificationsPageComponent} from './notifications/notifications-page/notifications-page.component';
import {TrackEmbedComponent} from './tracks/track-embed/track-embed.component';
import {ChannelFallbackHostComponent} from './channels/channel-fallback-host/channel-fallback-host.component';
import {PagesModule} from '@common/core/pages/shared/pages.module';
import {LandingComponent} from './landing/landing.component';
import {HomepageHostComponent} from './homepage-host/homepage-host.component';
import {CommentListComponent} from './tracks/track-page/comments/comment-list/comment-list.component';
import {CommentListItemComponent} from './tracks/track-page/comments/comment-list-item/comment-list-item.component';
import {WaveformModule} from './tracks/waveform/waveform.module';
import {MediaListItemModule} from './tracks/media-list-item/media-list-item.module';
import {NewCommentFormModule} from './tracks/new-comment-form/new-comment-form.module';
import {PlayerUiModule} from './player-ui.module';
import {TrackActionsBarModule} from './tracks/track-actions-bar/track-actions-bar.module';

@NgModule({
    imports: [
        UiModule,
        WebPlayerRoutingModule,
        UploadImageControlModule,
        PagesModule,
        WaveformModule,
        MediaListItemModule,
        NewCommentFormModule,
        PlayerUiModule,
        TrackActionsBarModule,

        // material
        MatSidenavModule,
        MatDialogModule,
        DragDropModule,
        MediaImageModule,
        MatChipsModule,
        UploadsModule,
        MatTableModule,
        MatSortModule,
        MatBadgeModule,
    ],
    declarations: [
        WebPlayerComponent,
        NavSidebarComponent,
        SearchSlideoutPanelComponent,
        AlbumComponent,
        TrackListComponent,
        QueueSidebarComponent,
        FullscreenOverlayComponent,
        LibraryTrackToggleButtonComponent,
        LibraryTracksComponent,
        LibraryAlbumsComponent,
        LibraryArtistsComponent,
        AlbumItemComponent,
        ArtistItemComponent,
        UserItemComponent,
        ArtistComponent,
        AlbumContextMenuComponent,
        CrupdatePlaylistModalComponent,
        TrackContextMenuComponent,
        ArtistContextMenuComponent,
        GenreComponent,
        ContextMenuPlaylistPanelComponent,
        PlaylistComponent,
        SearchComponent,
        UserItemComponent,
        PlaylistTrackContextMenuComponent,
        PlaylistContextMenuComponent,
        RadioPageComponent,
        ShareMediaItemModalComponent,
        LyricsModalComponent,
        UserLibraryComponent,
        LibraryPlaylistsComponent,
        ChannelShowComponent,
        ChannelContentComponent,
        PlayHistoryComponent,
        TrackItemComponent,
        GenreItemComponent,
        TruncatedDescriptionComponent,
        NotificationsPageComponent,
        TrackEmbedComponent,
        ChannelFallbackHostComponent,
        LandingComponent,
        HomepageHostComponent,

        CommentListComponent,
        CommentListItemComponent,
        TrackPageComponent,
    ],
    entryComponents: [
        AlbumContextMenuComponent,
        TrackContextMenuComponent,
        ArtistContextMenuComponent,
        PlaylistContextMenuComponent,
        PlaylistTrackContextMenuComponent,
        CrupdatePlaylistModalComponent,
        ShareMediaItemModalComponent,
        LyricsModalComponent,
        LandingComponent,
    ],
    providers: [
        {
            provide: AVAILABLE_CONTEXT_MENUS,
            useValue: WEB_PLAYER_CONTEXT_MENUS,
            multi: true,
        },
    ]
})
export class WebPlayerModule {}
