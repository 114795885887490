import * as i0 from "@angular/core";
export const WAVE_WIDTH = 1000;
export const WAVE_HEIGHT = 45;
export class WaveformGenerator {
    constructor() {
        this.barWidth = 3;
        this.barGap = 0.5;
        this.waveData = [];
    }
    generate(file) {
        if (!this.audioContext) {
            this.audioContext = new AudioContext();
        }
        return new Promise(resolve => {
            // preparing canvas
            if (!this.context) {
                this.canvas = document.createElement('canvas');
                this.context = this.canvas.getContext('2d');
                this.canvas.width = WAVE_WIDTH;
                this.canvas.height = WAVE_HEIGHT;
            }
            // read file buffer
            const reader = new FileReader();
            reader.onload = e => {
                this.audioContext.decodeAudioData(e.target['result'], buffer => {
                    this.extractBuffer(buffer, resolve);
                });
            };
            reader.readAsArrayBuffer(file);
        });
    }
    extractBuffer(bufferr, resolve) {
        const channelData = bufferr.getChannelData(0);
        const sections = WAVE_WIDTH;
        const len = Math.floor(channelData.length / sections);
        const maxHeight = WAVE_HEIGHT;
        const vals = [];
        for (let i = 0; i < sections; i += this.barWidth) {
            vals.push(this.bufferMeasure(i * len, len, channelData) * 10000);
        }
        for (let j = 0; j < sections; j += this.barWidth) {
            const scale = maxHeight / (Math.max(...vals));
            let val = this.bufferMeasure(j * len, len, channelData) * 10000;
            val *= scale;
            val += 1;
            this.drawBar(j, val);
        }
        // clear canvas for redrawing
        this.context.clearRect(0, 0, WAVE_WIDTH, WAVE_HEIGHT);
        resolve(this.waveData);
        this.waveData = [];
    }
    bufferMeasure(position, length, data) {
        let sum = 0.0;
        for (let i = position; i <= (position + length) - 1; i++) {
            sum += Math.pow(data[i], 2);
        }
        return Math.sqrt(sum / data.length);
    }
    drawBar(i, h) {
        let w = this.barWidth;
        if (this.barGap !== 0) {
            w *= Math.abs(1 - this.barGap);
        }
        const x = i + (w / 2), y = WAVE_HEIGHT - h;
        this.waveData.push([x, y, w, h]);
    }
}
WaveformGenerator.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WaveformGenerator_Factory() { return new WaveformGenerator(); }, token: WaveformGenerator, providedIn: "root" });
