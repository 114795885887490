import { filter } from 'rxjs/operators';
import { NavigationStart, Router } from "@angular/router";
import { Subscription } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class FullscreenOverlay {
    /**
     * FullscreenOverlay Constructor.
     */
    constructor(router) {
        this.router = router;
        /**
         * Whether fullscreen overlay is currently maximized.
         */
        this.maximized = false;
        /**
         * Currently active fullscreen overlay panel.
         */
        this.activePanel = 'video';
        /**
         * Active service subscriptions.
         */
        this.subscriptions = [];
    }
    /**
     * Init fullscreen overlay service.
     */
    init() {
        this.bindToRouterEvents();
    }
    /**
     * Check if fullscreen overlay is currently maximized.
     */
    isMaximized() {
        return this.maximized;
    }
    /**
     * Minimize fullscreen overlay.
     */
    minimize() {
        this.maximized = false;
        this.openVideoPanel();
    }
    /**
     * Maximize fullscreen overlay.
     */
    maximize() {
        this.maximized = true;
        //wait for animation to complete
        return new Promise(resolve => {
            setTimeout(() => { resolve(); }, 201);
        });
    }
    openQueuePanel() {
        this.activePanel = 'queue';
    }
    openVideoPanel() {
        this.activePanel = 'video';
    }
    activePanelIs(name) {
        return this.activePanel === name;
    }
    /**
     * Destroy fullscreen overlay service.
     */
    destroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }
    /**
     * Minimize fullscreen overlay when navigation occurs.
     */
    bindToRouterEvents() {
        const sub = this.router.events.pipe(filter(e => e instanceof NavigationStart))
            .subscribe(() => this.minimize());
        this.subscriptions.push(sub);
    }
}
FullscreenOverlay.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FullscreenOverlay_Factory() { return new FullscreenOverlay(i0.ɵɵinject(i1.Router)); }, token: FullscreenOverlay, providedIn: "root" });
