import { Settings } from 'common/core/config/settings.service';
import { slugifyString } from '@common/core/utils/slugify-string';
import * as i0 from "@angular/core";
import * as i1 from "../../common/core/config/settings.service";
export class WebPlayerUrls {
    constructor(settings) {
        this.settings = settings;
    }
    album(album, artist) {
        if (!artist)
            artist = album.artist;
        const artistName = artist ? artist.name : 'Various Artists';
        return ['/album', album.id, this.encodeItemName(artistName), this.encodeItemName(album.name)];
    }
    artist(artist, append) {
        const link = ['/artist', artist.id, this.encodeItemName(artist.name)];
        if (append) {
            link.push(append);
        }
        return link;
    }
    artistRadio(artist) {
        return ['radio/artist', artist.id, this.encodeItemName(artist.name)];
    }
    trackRadio(track) {
        return ['radio/track', track.id, this.encodeItemName(track.name)];
    }
    genre(genre) {
        return ['/genre', this.encodeItemName(genre.name)];
    }
    track(track) {
        return ['/track', track.id, this.encodeItemName(track.name)];
    }
    trackDownload(track) {
        return this.settings.getBaseUrl(true) + 'secure/tracks/' + track.id + '/download';
    }
    playlist(playlist) {
        return ['/playlists', playlist.id, this.encodeItemName(playlist.name)];
    }
    user(user, append) {
        if (!user)
            return ['/'];
        const link = ['/user', user.id, this.encodeItemName(user['display_name'] || user['name'])];
        if (append)
            link.push(append);
        return link;
    }
    search(query, tab) {
        const link = ['/search', query || ''];
        if (tab)
            link.push(tab);
        return link;
    }
    editArtist(artist) {
        return ['admin/artists', artist.id, 'edit'];
    }
    encodeItemName(name) {
        if (!name)
            return '';
        return slugifyString(name);
    }
    routerLinkToUrl(commands) {
        const uri = commands.map(command => {
            if (typeof command === 'string') {
                command = this.encodeItemName(command);
            }
            return command;
        }).join('/').replace(/^\/|\/$/g, '');
        return (this.settings.getBaseUrl() + uri);
    }
}
WebPlayerUrls.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WebPlayerUrls_Factory() { return new WebPlayerUrls(i0.ɵɵinject(i1.Settings)); }, token: WebPlayerUrls, providedIn: "root" });
