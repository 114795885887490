/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./channel-show.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/core/translations/translate.directive";
import * as i3 from "../../../../common/core/translations/translations.service";
import * as i4 from "../../../../common/core/config/settings.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../common/core/ui/ad-host/ad-host.component.ngfactory";
import * as i7 from "../../../../common/core/ui/ad-host/ad-host.component";
import * as i8 from "../../../../common/auth/current-user";
import * as i9 from "../../../../common/core/utils/lazy-loader.service";
import * as i10 from "./channel-content/channel-content.component.ngfactory";
import * as i11 from "./channel-content/channel-content.component";
import * as i12 from "../../web-player-urls.service";
import * as i13 from "./channel-show.component";
import * as i14 from "@angular/router";
var styles_ChannelShowComponent = [i0.styles];
var RenderType_ChannelShowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChannelShowComponent, data: {} });
export { RenderType_ChannelShowComponent as RenderType_ChannelShowComponent };
function View_ChannelShowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.name; _ck(_v, 2, 0, currVal_0); }); }
function View_ChannelShowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelShowComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ad-host", [["slot", "ads.general_top"]], [[8, "id", 0]], null, null, i6.View_AdHostComponent_0, i6.RenderType_AdHostComponent)), i1.ɵdid(4, 245760, null, 0, i7.AdHostComponent, [i1.ElementRef, i1.Renderer2, i4.Settings, i8.CurrentUser, i9.LazyLoaderService], { slot: [0, "slot"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "channel-content", [], null, null, null, i10.View_ChannelContentComponent_0, i10.RenderType_ChannelContentComponent)), i1.ɵdid(6, 4308992, null, 0, i11.ChannelContentComponent, [i12.WebPlayerUrls], { channel: [0, "channel"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "ad-host", [["class", "margin-bottom"], ["slot", "ads.general_bottom"]], [[8, "id", 0]], null, null, i6.View_AdHostComponent_0, i6.RenderType_AdHostComponent)), i1.ɵdid(8, 245760, null, 0, i7.AdHostComponent, [i1.ElementRef, i1.Renderer2, i4.Settings, i8.CurrentUser, i9.LazyLoaderService], { slot: [0, "slot"] }, null)], function (_ck, _v) { var currVal_0 = !_v.context.ngIf.hide_title; _ck(_v, 2, 0, currVal_0); var currVal_2 = "ads.general_top"; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.ngIf; _ck(_v, 6, 0, currVal_3); var currVal_5 = "ads.general_bottom"; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 4).randomId; _ck(_v, 3, 0, currVal_1); var currVal_4 = i1.ɵnov(_v, 8).randomId; _ck(_v, 7, 0, currVal_4); }); }
export function View_ChannelShowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChannelShowComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.channel$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ChannelShowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "channel-show", [], null, null, null, View_ChannelShowComponent_0, RenderType_ChannelShowComponent)), i1.ɵdid(1, 114688, null, 0, i13.ChannelShowComponent, [i14.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChannelShowComponentNgFactory = i1.ɵccf("channel-show", i13.ChannelShowComponent, View_ChannelShowComponent_Host_0, { channel: "channel" }, {}, []);
export { ChannelShowComponentNgFactory as ChannelShowComponentNgFactory };
