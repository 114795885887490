import { Router } from '@angular/router';
import { Genres } from '../genres.service';
import { WebPlayerState } from '../../web-player-state.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../genres.service";
import * as i2 from "@angular/router";
import * as i3 from "../../web-player-state.service";
export class GenreArtistsResolver {
    constructor(genres, router, state) {
        this.genres = genres;
        this.router = router;
        this.state = state;
    }
    resolve(route, state) {
        this.state.loading = true;
        const name = route.paramMap.get('name');
        return this.genres.get(name).pipe(catchError(() => {
            this.state.loading = false;
            this.router.navigate(['/']);
            return EMPTY;
        }), mergeMap(response => {
            this.state.loading = false;
            if (response.artists) {
                return of(response);
            }
            else {
                this.router.navigate(['/']);
                return EMPTY;
            }
        }));
    }
}
GenreArtistsResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GenreArtistsResolver_Factory() { return new GenreArtistsResolver(i0.ɵɵinject(i1.Genres), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.WebPlayerState)); }, token: GenreArtistsResolver, providedIn: "root" });
