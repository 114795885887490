import { RouteReuseStrategy } from '@angular/router';
import { objectsAreEqual } from '@common/core/utils/objects-are-equal';
import * as i0 from "@angular/core";
export class CustomRouteReuseStrategy extends RouteReuseStrategy {
    shouldDetach(route) { return false; }
    store(route, detachedTree) { }
    shouldAttach(route) { return false; }
    retrieve(route) { return null; }
    shouldReuseRoute(future, current) {
        // first use the global Reuse Strategy evaluation function,
        // which will return true, when we are navigating from the same component to itself
        let shouldReuse = future.routeConfig === current.routeConfig;
        // reuse if route params did not change, this will allow for
        // tabs on artist/user profile pages to work without re-rendering
        if (shouldReuse && current.data.noReuse && !objectsAreEqual(future.params, current.params)) {
            shouldReuse = false;
        }
        return shouldReuse;
    }
}
CustomRouteReuseStrategy.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomRouteReuseStrategy_Factory() { return new CustomRouteReuseStrategy(); }, token: CustomRouteReuseStrategy, providedIn: "root" });
