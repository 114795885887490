import { Router } from '@angular/router';
import { Playlists } from '../playlists.service';
import { Player } from '../../player/player.service';
import { Settings } from 'common/core/config/settings.service';
import { WebPlayerState } from '../../web-player-state.service';
import { WebPlayerImagesService } from '../../web-player-images.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../playlists.service";
import * as i2 from "../../player/player.service";
import * as i3 from "../../../../common/core/config/settings.service";
import * as i4 from "@angular/router";
import * as i5 from "../../web-player-state.service";
import * as i6 from "../../web-player-images.service";
export class PlaylistResolver {
    constructor(playlists, player, settings, router, state, images) {
        this.playlists = playlists;
        this.player = player;
        this.settings = settings;
        this.router = router;
        this.state = state;
        this.images = images;
    }
    resolve(route, state) {
        this.state.loading = true;
        const id = +route.paramMap.get('id');
        return this.playlists.get(id).pipe(catchError(() => {
            this.state.loading = false;
            this.router.navigate(['/']);
            return EMPTY;
        }), mergeMap(response => {
            this.state.loading = false;
            if (response.playlist) {
                return of(response);
            }
            else {
                this.router.navigate(['/']);
                return EMPTY;
            }
        }));
    }
}
PlaylistResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlaylistResolver_Factory() { return new PlaylistResolver(i0.ɵɵinject(i1.Playlists), i0.ɵɵinject(i2.Player), i0.ɵɵinject(i3.Settings), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.WebPlayerState), i0.ɵɵinject(i6.WebPlayerImagesService)); }, token: PlaylistResolver, providedIn: "root" });
