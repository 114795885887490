/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./homepage-host.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../landing/landing.component.ngfactory";
import * as i3 from "../landing/landing.component";
import * as i4 from "../../../common/core/http/app-http-client.service";
import * as i5 from "../../../common/core/config/settings.service";
import * as i6 from "../../../common/core/translations/translations.service";
import * as i7 from "../../../common/core/meta/meta-tags.service";
import * as i8 from "../web-player-urls.service";
import * as i9 from "@angular/router";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../web-player.component.ngfactory";
import * as i12 from "../web-player.component";
import * as i13 from "../search/search-slideout-panel/search-slideout-panel.service";
import * as i14 from "../player/player.service";
import * as i15 from "../web-player-state.service";
import * as i16 from "../fullscreen-overlay/fullscreen-overlay.service";
import * as i17 from "../web-player-images.service";
import * as i18 from "@angular/cdk/overlay";
import * as i19 from "@angular/common";
import * as i20 from "./homepage-host.component";
import * as i21 from "../../../common/auth/current-user";
var styles_HomepageHostComponent = [i0.styles];
var RenderType_HomepageHostComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomepageHostComponent, data: {} });
export { RenderType_HomepageHostComponent as RenderType_HomepageHostComponent };
function View_HomepageHostComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "landing", [], null, null, null, i2.View_LandingComponent_0, i2.RenderType_LandingComponent)), i1.ɵdid(2, 114688, null, 0, i3.LandingComponent, [i4.AppHttpClient, i5.Settings, i6.Translations, i7.MetaTagsService, i8.WebPlayerUrls, i9.Router, i1.ChangeDetectorRef, i10.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_HomepageHostComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "web-player", [["id", "web-player"]], null, null, null, i11.View_WebPlayerComponent_0, i11.RenderType_WebPlayerComponent)), i1.ɵdid(1, 245760, null, 0, i12.WebPlayerComponent, [i13.SearchSlideoutPanel, i14.Player, i1.Renderer2, i15.WebPlayerState, i16.FullscreenOverlay, i5.Settings, i17.WebPlayerImagesService, i18.OverlayContainer, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_HomepageHostComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_HomepageHostComponent_1)), i1.ɵdid(1, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i19.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["player", 2]], null, 0, null, View_HomepageHostComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.shouldShowLanding$)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_HomepageHostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "homepage-host", [], null, null, null, View_HomepageHostComponent_0, RenderType_HomepageHostComponent)), i1.ɵdid(1, 114688, null, 0, i20.HomepageHostComponent, [i21.CurrentUser, i9.Router, i5.Settings], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomepageHostComponentNgFactory = i1.ɵccf("homepage-host", i20.HomepageHostComponent, View_HomepageHostComponent_Host_0, {}, {}, []);
export { HomepageHostComponentNgFactory as HomepageHostComponentNgFactory };
