import { Playlists } from "./playlists.service";
import { CurrentUser } from "common/auth/current-user";
import * as i0 from "@angular/core";
import * as i1 from "./playlists.service";
import * as i2 from "../../../common/auth/current-user";
export class UserPlaylists {
    /**
     * UserPlaylists Constructor.
     */
    constructor(playlistsApi, currentUser) {
        this.playlistsApi = playlistsApi;
        this.currentUser = currentUser;
        /**
         * All user created and followed playlists.
         */
        this.playlists = [];
        this.bindToPlaylistEvents();
    }
    /**
     * Add specified playlists.
     */
    add(playlists) {
        if (!Array.isArray(playlists))
            playlists = [playlists];
        playlists = playlists.filter(playlist => {
            return !this.playlists.find(curr => curr.id === playlist.id);
        });
        this.playlists = playlists.concat(this.playlists);
    }
    /**
     * Remove specified playlist.
     */
    remove(playlist) {
        let i = this.playlists.findIndex(predicate => predicate.id === playlist.id);
        this.playlists.splice(i, 1);
    }
    /**
     * Get all user playlists.
     */
    get() {
        return this.playlists;
    }
    /**
     * Set specified playlists on the service.
     */
    set(playlists) {
        if (!playlists)
            playlists = [];
        this.playlists = playlists.slice();
    }
    follow(playlist) {
        this.playlistsApi.follow(playlist.id).subscribe(() => {
            this.add(playlist);
        });
    }
    unfollow(playlist) {
        this.playlistsApi.unfollow(playlist.id).subscribe(() => {
            this.remove(playlist);
        });
    }
    /**
     * Check if current user is following specified playlist.
     */
    following(id) {
        const playlist = this.playlists.find(playlist => playlist.id === id);
        //if user is playlist creator, then he is not following it
        return playlist && !this.isCreator(playlist);
    }
    /**
     * Check if current user is creator of specified playlist.
     */
    isCreator(playlist) {
        if (!playlist || !playlist.editors)
            return false;
        return playlist.editors.findIndex(user => user.id === this.currentUser.get('id')) > -1;
    }
    /**
     * Reset user playlists service.
     */
    reset() {
        this.playlists = [];
    }
    bindToPlaylistEvents() {
        this.playlistsApi.deleted$.subscribe(ids => {
            ids.forEach(id => {
                const i = this.playlists.findIndex(playlist => playlist.id === id);
                this.playlists.splice(i, 1);
            });
        });
    }
}
UserPlaylists.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserPlaylists_Factory() { return new UserPlaylists(i0.ɵɵinject(i1.Playlists), i0.ɵɵinject(i2.CurrentUser)); }, token: UserPlaylists, providedIn: "root" });
