import * as tslib_1 from "tslib";
import { PlayerState } from '../player-state.service';
import { NgZone } from '@angular/core';
import { PlayerQueue } from '../player-queue.service';
import { WebPlayerImagesService } from '../../web-player-images.service';
import { isAbsoluteUrl } from '@common/core/utils/is-absolute-url';
import { Settings } from '@common/core/config/settings.service';
import * as i0 from "@angular/core";
import * as i1 from "../player-state.service";
import * as i2 from "../player-queue.service";
import * as i3 from "../../web-player-images.service";
import * as i4 from "../../../../common/core/config/settings.service";
export class Html5Strategy {
    constructor(state, zone, queue, wpImages, settings) {
        this.state = state;
        this.zone = zone;
        this.queue = queue;
        this.wpImages = wpImages;
        this.settings = settings;
        /**
         * Whether player is already bootstrapped.
         */
        this.bootstrapped = false;
        /**
         * Volume that should be set after player is bootstrapped.
         * Number between 1 and 100.
         */
        this.pendingVolume = null;
    }
    play() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.cueTrack(this.queue.getCurrent());
            this.html5.play();
            this.state.playing = true;
        });
    }
    pause() {
        this.html5.pause();
        this.state.playing = false;
    }
    stop() {
        this.pause();
        this.seekTo(0);
        this.state.playing = false;
    }
    seekTo(time) {
        if (time === Infinity)
            return;
        this.html5.currentTime = time;
    }
    /**
     * Get loaded track duration in seconds.
     */
    getDuration() {
        return this.html5.seekable.length > 0 ?
            this.html5.seekable.end(0) :
            0;
    }
    /**
     * Get elapsed time in seconds since the track started playing
     */
    getCurrentTime() {
        return this.html5 ? this.html5.currentTime : 0;
    }
    /**
     * Set html5 player volume to float between 0 and 1.
     */
    setVolume(number) {
        if (!this.html5) {
            this.pendingVolume = number;
        }
        else {
            this.html5.volume = number / 100;
        }
    }
    mute() {
        this.html5.muted = true;
    }
    unMute() {
        this.html5.muted = false;
    }
    getCuedTrack() {
        return this.cuedTrack;
    }
    ready() {
        return this.bootstrapped;
    }
    cueTrack(track) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.cueing === track || this.cuedTrack === track)
                return;
            this.cueing = track;
            this.state.buffering = true;
            this.bootstrap();
            this.html5.src = isAbsoluteUrl(track.url) ? track.url : this.settings.getBaseUrl(true) + track.url;
            this.html5.poster = this.wpImages.getTrackImage(track);
            this.cuedTrack = track;
            this.cueing = null;
            return new Promise(resolve => resolve());
        });
    }
    destroy() {
        this.html5 && this.html5.remove();
        this.html5 = null;
        this.bootstrapped = false;
        this.cuedTrack = null;
    }
    bootstrap() {
        if (this.bootstrapped)
            return;
        this.html5 = document.createElement('video');
        this.html5.setAttribute('playsinline', 'true');
        this.html5.id = 'html5-player';
        document.querySelector('.html5-player').appendChild(this.html5);
        this.handlePlayerReadyEvent();
        this.handlePlayerStateChangeEvents();
        this.bootstrapped = true;
    }
    handlePlayerStateChangeEvents() {
        this.html5.addEventListener('ended', () => {
            this.state.firePlaybackEnded();
            this.setState('playing', false);
        });
        this.html5.addEventListener('playing', () => {
            this.setState('playing', true);
        });
        this.html5.addEventListener('pause', () => {
            this.setState('playing', false);
        });
        this.html5.addEventListener('error', () => {
            this.cuedTrack = null;
            this.setState('playing', false);
            this.state.firePlaybackEnded();
        });
    }
    setState(name, value) {
        this.zone.run(() => this.state[name] = value);
    }
    handlePlayerReadyEvent(resolve) {
        if (this.state.muted)
            this.mute();
        this.bootstrapped = true;
        resolve && resolve();
        this.state.fireReadyEvent();
        if (this.pendingVolume) {
            this.setVolume(this.pendingVolume);
            this.pendingVolume = null;
        }
    }
}
Html5Strategy.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Html5Strategy_Factory() { return new Html5Strategy(i0.ɵɵinject(i1.PlayerState), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i2.PlayerQueue), i0.ɵɵinject(i3.WebPlayerImagesService), i0.ɵɵinject(i4.Settings)); }, token: Html5Strategy, providedIn: "root" });
