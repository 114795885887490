import * as i0 from "@angular/core";
export class SelectedTracks {
    constructor() {
        /**
         * Current selected tracks.
         */
        this.tracks = [];
    }
    /**
     * Check if specified track is selected.
     */
    has(track) {
        return this.tracks.findIndex(curr => curr.id === track.id) > -1;
    }
    /**
     * Check if there are no selected tracks currently.
     */
    empty() {
        return this.tracks.length === 0;
    }
    /**
     * Add track if it is not selected already, otherwise remove it.
     */
    toggle(track) {
        if (this.has(track)) {
            this.remove(track);
        }
        else {
            this.add(track);
        }
    }
    /**
     * Select specified track.
     */
    add(track) {
        if (this.has(track))
            return;
        this.tracks.push(track);
    }
    /**
     * Remove selected track.
     */
    remove(track) {
        const i = this.tracks.findIndex(curr => curr.id === track.id);
        this.tracks.splice(i, 1);
    }
    /**
     * Clear all selected tracks.
     */
    clear() {
        this.tracks = [];
    }
    /**
     * Return all selected tracks.
     */
    all() {
        return this.tracks;
    }
}
SelectedTracks.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SelectedTracks_Factory() { return new SelectedTracks(); }, token: SelectedTracks, providedIn: "root" });
