import { Router } from '@angular/router';
import { WebPlayerState } from "../web-player-state.service";
import { Artists } from "../artists/artists.service";
import { Tracks } from "../tracks/tracks.service";
import * as i0 from "@angular/core";
import * as i1 from "../artists/artists.service";
import * as i2 from "../tracks/tracks.service";
import * as i3 from "@angular/router";
import * as i4 from "../web-player-state.service";
export class RadioPageResolver {
    constructor(artists, tracks, router, state) {
        this.artists = artists;
        this.tracks = tracks;
        this.router = router;
        this.state = state;
    }
    resolve(route, state) {
        this.state.loading = true;
        let id = +route.paramMap.get('id'), request;
        if (route.data.type === 'artist') {
            request = this.artists.getRadioRecommendations(id);
        }
        else {
            request = this.tracks.getRadioRecommendations(id);
        }
        return request.toPromise().then(response => {
            this.state.loading = false;
            if (response) {
                return response;
            }
            else {
                this.router.navigate(['/']);
                return null;
            }
        }).catch(() => {
            this.state.loading = false;
            this.router.navigate(['/']);
        });
    }
}
RadioPageResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RadioPageResolver_Factory() { return new RadioPageResolver(i0.ɵɵinject(i1.Artists), i0.ɵɵinject(i2.Tracks), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.WebPlayerState)); }, token: RadioPageResolver, providedIn: "root" });
