import { BehaviorSubject } from 'rxjs';
import { CommentsService } from '@common/shared/comments/comments.service';
import { TRACK_MODEL } from '../../models/Track';
import { Toast } from '@common/core/ui/toast.service';
import { CurrentUser } from '@common/auth/current-user';
import { Player } from '../player/player.service';
import { finalize } from 'rxjs/operators';
import { Tracks } from './tracks.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/shared/comments/comments.service";
import * as i2 from "../../../common/core/ui/toast.service";
import * as i3 from "../../../common/auth/current-user";
import * as i4 from "../player/player.service";
import * as i5 from "./tracks.service";
export class TrackCommentsService {
    constructor(comments, toast, currentUser, player, tracks) {
        this.comments = comments;
        this.toast = toast;
        this.currentUser = currentUser;
        this.player = player;
        this.tracks = tracks;
        this.waveComments$ = new BehaviorSubject([]);
        this.pagination$ = new BehaviorSubject(null);
        this.loading$ = new BehaviorSubject(false);
        this.loadingMore$ = new BehaviorSubject(false);
        this.markerPosition$ = new BehaviorSubject(null);
    }
    create(content, inReplyTo) {
        return new Promise(resolve => {
            this.loading$.next(true);
            const position = this.markerPosition$.value != null ?
                this.markerPosition$.value :
                (this.player.getCurrentTime() / this.player.getDuration()) * 100;
            this.comments.create({
                commentable_id: this.track.id,
                commentable_type: TRACK_MODEL,
                content: content,
                position: position || 0,
                inReplyTo,
            }).pipe(finalize(() => this.loading$.next(false))).subscribe(response => {
                const newComment = response.comment;
                newComment.user = this.currentUser.getModel();
                if (inReplyTo) {
                    const i = this.pagination$.value.data.findIndex(c => c.id === inReplyTo.id);
                    this.pagination$.value.data.splice(i + 1, 0, newComment);
                    this.pagination$.next(Object.assign({}, this.pagination$.value));
                }
                else {
                    // wave comments
                    this.waveComments$.next([
                        ...this.waveComments$.value, newComment
                    ]);
                    // paginated comments
                    this.pagination$.value.data.unshift(newComment);
                    this.pagination$.next(Object.assign({}, this.pagination$.value));
                }
                this.track.comments_count++;
                this.toast.open('Comment posted.');
                resolve(response.comment);
            });
        });
    }
    loadMore() {
        this.loadingMore$.next(true);
        return this.tracks.loadComments(this.track.id, { page: this.pagination$.value.current_page + 1 })
            .pipe(finalize(() => this.loadingMore$.next(false)))
            .subscribe(response => {
            this.pagination$.next(Object.assign({}, response.pagination, { data: [...this.pagination$.value.data, ...response.pagination.data] }));
        });
    }
}
TrackCommentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TrackCommentsService_Factory() { return new TrackCommentsService(i0.ɵɵinject(i1.CommentsService), i0.ɵɵinject(i2.Toast), i0.ɵɵinject(i3.CurrentUser), i0.ɵɵinject(i4.Player), i0.ɵɵinject(i5.Tracks)); }, token: TrackCommentsService, providedIn: "root" });
