import { UserLibrary } from './web-player/users/user-library/user-library.service';
import { UserPlaylists } from './web-player/playlists/user-playlists.service';
import { Bootstrapper } from 'common/core/bootstrapper.service';
import * as i0 from "@angular/core";
export class BeMusicBootstrapper extends Bootstrapper {
    handleData(encodedData) {
        const data = super.handleData(encodedData);
        // set user library
        this.injector.get(UserLibrary).setLikes(data['likes']);
        // set user playlists
        this.injector.get(UserPlaylists).set(data['playlists']);
        return data;
    }
}
BeMusicBootstrapper.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BeMusicBootstrapper_Factory() { return new BeMusicBootstrapper(i0.ɵɵinject(i0.INJECTOR)); }, token: BeMusicBootstrapper, providedIn: "root" });
