/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-play-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "../../../../common/core/translations/translate.directive";
import * as i9 from "../../../../common/core/translations/translations.service";
import * as i10 from "../../../../common/core/config/settings.service";
import * as i11 from "@angular/common";
import * as i12 from "./header-play-button.component";
import * as i13 from "../../player/player.service";
var styles_HeaderPlayButtonComponent = [i0.styles];
var RenderType_HeaderPlayButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderPlayButtonComponent, data: {} });
export { RenderType_HeaderPlayButtonComponent as RenderType_HeaderPlayButtonComponent };
function View_HeaderPlayButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "play-button action-button"], ["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.player.playMediaItem(_co.queueId, _co.tracks, _co.select) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "play-arrow"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(5, 4341760, null, 0, i8.TranslateDirective, [i1.ElementRef, i9.Translations, i10.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Play"]))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 1, 0, currVal_2); var currVal_5 = "play-arrow"; _ck(_v, 3, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 3).inline; var currVal_4 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_3, currVal_4); }); }
function View_HeaderPlayButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "play-button action-button"], ["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.player.pause() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "pause"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(5, 4341760, null, 0, i8.TranslateDirective, [i1.ElementRef, i9.Translations, i10.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Pause"]))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 1, 0, currVal_2); var currVal_5 = "pause"; _ck(_v, 3, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 3).inline; var currVal_4 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_3, currVal_4); }); }
export function View_HeaderPlayButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderPlayButtonComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderPlayButtonComponent_2)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.player.mediaItemPlaying(_co.queueId); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.player.mediaItemPlaying(_co.queueId); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_HeaderPlayButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "header-play-button", [], null, null, null, View_HeaderPlayButtonComponent_0, RenderType_HeaderPlayButtonComponent)), i1.ɵdid(1, 49152, null, 0, i12.HeaderPlayButtonComponent, [i13.Player], null, null)], null, null); }
var HeaderPlayButtonComponentNgFactory = i1.ɵccf("header-play-button", i12.HeaderPlayButtonComponent, View_HeaderPlayButtonComponent_Host_0, { queueId: "queueId", tracks: "tracks", select: "select" }, {}, []);
export { HeaderPlayButtonComponentNgFactory as HeaderPlayButtonComponentNgFactory };
