import {Injectable} from '@angular/core';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {BackendResponse} from '@common/core/types/backend-response';
import {PaginationParams} from '@common/core/types/pagination/pagination-params';
import {Notification} from './notification';
import {PaginatedBackendResponse} from '@common/core/types/pagination/paginated-backend-response';

const BASE_URI = 'notifications';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    constructor(private http: AppHttpClient) {}

    public markAsRead(ids: string[]): BackendResponse<{unreadCount: number}> {
        return this.http.post(`${BASE_URI}/mark-as-read`, {ids});
    }

    public all(params: PaginationParams & {userId?: number}): PaginatedBackendResponse<Notification> {
        return this.http.get(BASE_URI, params);
    }
}
