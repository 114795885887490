import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class Artists {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    get(id, params = {}) {
        return this.httpClient.get('artists/' + id, params);
    }
    create(payload) {
        return this.httpClient.post('artists', payload);
    }
    update(id, payload) {
        return this.httpClient.put('artists/' + id, payload);
    }
    paginateArtistAlbums(id, page = 1) {
        return this.httpClient.get('artists/' + id + '/albums', { page });
    }
    getRadioRecommendations(id) {
        return this.httpClient.get(`radio/artist/${id}`);
    }
    delete(ids) {
        return this.httpClient.delete('artists', { ids });
    }
}
Artists.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Artists_Factory() { return new Artists(i0.ɵɵinject(i1.AppHttpClient)); }, token: Artists, providedIn: "root" });
