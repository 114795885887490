import * as i0 from "@angular/core";
export class FormattedDuration {
    fromMilliseconds(ms) {
        return this.fromNumber(ms, 'ms');
    }
    fromSeconds(seconds) {
        return this.fromNumber(seconds, 'sec');
    }
    fromNumber(originalSeconds, type) {
        if (!originalSeconds || originalSeconds < 0) {
            return '0:00';
        }
        // create new date at "0:00:0" time
        const date = new Date(2000, 1, 1);
        if (type === 'sec') {
            date.setSeconds(originalSeconds);
        }
        else {
            date.setMilliseconds(originalSeconds);
        }
        const hours = date.getHours().toString();
        let minutes = date.getMinutes().toString();
        // minutes should only have zero prepended if hours exist
        // example: 01:05:03 and 5:03
        if (hours !== '0') {
            minutes = this.prependZero(minutes);
        }
        const seconds = this.prependZero(date.getSeconds().toString());
        let formatted = `${minutes}:${seconds}`;
        // don't show hours at all if length less then 1 hour
        if (hours !== '0') {
            formatted = `${hours}:${formatted}`;
        }
        return formatted;
    }
    prependZero(number) {
        if (number.length === 1) {
            number = '0' + number;
        }
        return number;
    }
    toVerboseString(ms) {
        const date = new Date(ms);
        let str = '';
        const hours = date.getUTCHours();
        if (hours)
            str += hours + 'hr ';
        const minutes = date.getUTCMinutes();
        if (minutes)
            str += minutes + 'min ';
        const seconds = date.getUTCMinutes();
        if (seconds && !hours && !minutes)
            str += seconds + 'sec ';
        return str;
    }
}
FormattedDuration.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormattedDuration_Factory() { return new FormattedDuration(); }, token: FormattedDuration, providedIn: "root" });
