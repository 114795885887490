import { UploadValidator } from '../../common/uploads/validation/upload-validator';
import { FileSizeValidation } from '../../common/uploads/validation/validations/file-size-validation';
import { convertToBytes } from '../../common/core/utils/convertToBytes';
import { FileTypeValidation } from '../../common/uploads/validation/validations/file-type-validation';
import * as i0 from "@angular/core";
import * as i1 from "../../common/core/config/settings.service";
import * as i2 from "../../common/core/ui/toast.service";
import * as i3 from "../../common/core/translations/translations.service";
export class ImageUploadValidator extends UploadValidator {
    constructor() {
        super(...arguments);
        this.DEFAULT_MAX_SIZE_MB = 2;
        this.showToast = true;
    }
    initValidations() {
        const validations = [
            new FileSizeValidation({ maxSize: convertToBytes(this.DEFAULT_MAX_SIZE_MB, 'MB') }, this.i18n),
            new FileTypeValidation({ types: ['image'] }, this.i18n),
        ];
        this.validations.push(...validations);
    }
}
ImageUploadValidator.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImageUploadValidator_Factory() { return new ImageUploadValidator(i0.ɵɵinject(i1.Settings), i0.ɵɵinject(i2.Toast), i0.ɵɵinject(i3.Translations)); }, token: ImageUploadValidator, providedIn: "root" });
