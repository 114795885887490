import { ElementRef } from '@angular/core';
import * as i0 from "@angular/core";
export class WebPlayerState {
    constructor() {
        this.loading = false;
        this.isMobile = false;
        this.isMobile = window.matchMedia && window.matchMedia('(max-width: 768px)').matches;
    }
}
WebPlayerState.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WebPlayerState_Factory() { return new WebPlayerState(); }, token: WebPlayerState, providedIn: "root" });
