/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-item.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../shared/media-image/media-image.component.ngfactory";
import * as i5 from "../../shared/media-image/media-image.component";
import * as i6 from "../../../../common/core/config/settings.service";
import * as i7 from "../../../../common/core/translations/translate.directive";
import * as i8 from "../../../../common/core/translations/translations.service";
import * as i9 from "./user-item.component";
import * as i10 from "../../web-player-urls.service";
import * as i11 from "../../web-player-images.service";
var styles_UserItemComponent = [i0.styles];
var RenderType_UserItemComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_UserItemComponent, data: {} });
export { RenderType_UserItemComponent as RenderType_UserItemComponent };
export function View_UserItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "img-container"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "media-image", [["default", "artist"]], null, null, null, i4.View_MediaImageComponent_0, i4.RenderType_MediaImageComponent)), i1.ɵdid(3, 573440, null, 0, i5.MediaImageComponent, [i6.Settings], { media: [0, "media"], default: [1, "default"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "user-name primary-text"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "followers secondary-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(11, 4341760, null, 0, i7.TranslateDirective, [i1.ElementRef, i8.Translations, i6.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Followers"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.urls.user(_co.user); _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.user; var currVal_4 = "artist"; _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_7 = _co.urls.user(_co.user); _ck(_v, 6, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵnov(_v, 6).target; var currVal_6 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_5, currVal_6); var currVal_8 = _co.user["display_name"]; _ck(_v, 7, 0, currVal_8); var currVal_9 = _co.user["followers_count"]; _ck(_v, 9, 0, currVal_9); }); }
export function View_UserItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-item", [["class", "media-grid-item"]], null, null, null, View_UserItemComponent_0, RenderType_UserItemComponent)), i1.ɵdid(1, 49152, null, 0, i9.UserItemComponent, [i10.WebPlayerUrls, i6.Settings, i11.WebPlayerImagesService], null, null)], null, null); }
var UserItemComponentNgFactory = i1.ɵccf("user-item", i9.UserItemComponent, View_UserItemComponent_Host_0, { scrollContainer: "scrollContainer", user: "user" }, {}, []);
export { UserItemComponentNgFactory as UserItemComponentNgFactory };
