import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TrackActionsBarComponent} from './track-actions-bar.component';
import {UiModule} from '@common/core/ui/ui.module';


@NgModule({
    declarations: [
        TrackActionsBarComponent,
    ],
    imports: [
        CommonModule,
        UiModule,
    ],
    exports: [
        TrackActionsBarComponent,
    ]
})
export class TrackActionsBarModule {
}
