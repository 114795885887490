/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./truncated-description.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/core/translations/translate.directive";
import * as i3 from "../../../../../common/core/translations/translations.service";
import * as i4 from "../../../../../common/core/config/settings.service";
import * as i5 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@angular/common";
import * as i10 from "./truncated-description.component";
var styles_TruncatedDescriptionComponent = [i0.styles];
var RenderType_TruncatedDescriptionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TruncatedDescriptionComponent, data: {} });
export { RenderType_TruncatedDescriptionComponent as RenderType_TruncatedDescriptionComponent };
function View_TruncatedDescriptionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Show Less"]))], null, null); }
function View_TruncatedDescriptionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Show More"]))], null, null); }
function View_TruncatedDescriptionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "more-button"], ["mat-stroked-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleContent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_TruncatedDescriptionComponent_2)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["showMore", 2]], 0, 0, null, View_TruncatedDescriptionComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.showingAll$)); var currVal_3 = i1.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_TruncatedDescriptionComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { contentEl: 0 }), i1.ɵqud(402653184, 2, { contentWrapperEl: 0 }), (_l()(), i1.ɵeld(2, 0, [[2, 0], ["contentWrapperEl", 1]], null, 3, "div", [["class", "content-wrapper"]], [[2, "showing-all", null]], null, null, null, null)), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["contentEl", 1]], null, 1, "div", [["class", "content"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TruncatedDescriptionComponent_1)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.moreButtonVisible$)); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.showingAll$)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.linkifiedContent$)); _ck(_v, 4, 0, currVal_1); }); }
export function View_TruncatedDescriptionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "truncated-description", [], null, null, null, View_TruncatedDescriptionComponent_0, RenderType_TruncatedDescriptionComponent)), i1.ɵdid(1, 638976, null, 0, i10.TruncatedDescriptionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TruncatedDescriptionComponentNgFactory = i1.ɵccf("truncated-description", i10.TruncatedDescriptionComponent, View_TruncatedDescriptionComponent_Host_0, { content: "content" }, {}, []);
export { TruncatedDescriptionComponentNgFactory as TruncatedDescriptionComponentNgFactory };
