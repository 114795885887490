import { Router } from '@angular/router';
import { WebPlayerUrls } from "../../web-player-urls.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../web-player-urls.service";
export class SearchTabValidGuard {
    constructor(router, urls) {
        this.router = router;
        this.urls = urls;
        this.validTabs = ['artists', 'albums', 'songs', 'users', 'playlists'];
    }
    canActivate(next, state) {
        const valid = this.validTabs.indexOf(next.params.tab) > -1;
        if (!valid)
            this.router.navigate(this.urls.search(next.params.query));
        return valid;
    }
}
SearchTabValidGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchTabValidGuard_Factory() { return new SearchTabValidGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.WebPlayerUrls)); }, token: SearchTabValidGuard, providedIn: "root" });
