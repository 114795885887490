import { Settings } from 'common/core/config/settings.service';
import { DefaultImagePaths } from './default-image-paths.enum';
import * as i0 from "@angular/core";
import * as i1 from "../../common/core/config/settings.service";
export class WebPlayerImagesService {
    constructor(settings) {
        this.settings = settings;
    }
    getDefault(type) {
        if (type === 'artist') {
            return this.settings.getBaseUrl(true) + DefaultImagePaths.artistSmall;
        }
        else if (type === 'artistBig') {
            return this.settings.getBaseUrl(true) + DefaultImagePaths.artistBig;
        }
        else {
            return this.settings.getBaseUrl(true) + DefaultImagePaths.album;
        }
    }
    getPlaylistImage(playlist) {
        if (playlist.image)
            return playlist.image;
        if (playlist.tracks && playlist.tracks[0] && playlist.tracks[0].album)
            return playlist.tracks[0].album.image;
        return this.getDefault('album');
    }
    getAlbumImage(album) {
        if (album && album.image)
            return album.image;
        return this.getDefault('album');
    }
    getTrackImage(track) {
        if (track.image) {
            return track.image;
        }
        else if (track.album && track.album.image) {
            return track.album.image;
        }
        else {
            return DefaultImagePaths.album;
        }
    }
}
WebPlayerImagesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WebPlayerImagesService_Factory() { return new WebPlayerImagesService(i0.ɵɵinject(i1.Settings)); }, token: WebPlayerImagesService, providedIn: "root" });
