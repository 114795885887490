import { AppHttpClient } from 'common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class Lyrics {
    constructor(http) {
        this.http = http;
    }
    get(trackId) {
        return this.http.get(`tracks/${trackId}/lyrics`);
    }
    create(payload) {
        return this.http.post('lyrics', payload);
    }
    update(id, payload) {
        return this.http.put('lyrics/' + id, payload);
    }
    delete(ids) {
        return this.http.delete('lyrics', { ids });
    }
}
Lyrics.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Lyrics_Factory() { return new Lyrics(i0.ɵɵinject(i1.AppHttpClient)); }, token: Lyrics, providedIn: "root" });
