import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class PlayerTracksService {
    constructor(http) {
        this.http = http;
    }
    load(queueId, lastTrack) {
        return new Promise(resolve => {
            return this.http.post('player/tracks', { queueId, lastTrack })
                .subscribe(response => {
                resolve(response.tracks);
            }, () => resolve([]));
        });
    }
}
PlayerTracksService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlayerTracksService_Factory() { return new PlayerTracksService(i0.ɵɵinject(i1.AppHttpClient)); }, token: PlayerTracksService, providedIn: "root" });
