import {ChangeDetectionStrategy, Component, NgZone, OnInit} from '@angular/core';
import {CurrentUser} from '@common/auth/current-user';
import {BehaviorSubject} from 'rxjs';
import {Notification} from '../notification';
import {WebPlayerUrls} from '../../web-player-urls.service';
import {Track, TRACK_MODEL} from '../../../models/Track';
import {NotificationType} from '../notification-type.enum';
import {Album} from '../../../models/Album';
import {NotificationsService} from '../notifications.service';
import {ActivatedRoute} from '@angular/router';
import {PaginationResponse} from '@common/core/types/pagination/pagination-response';
import {filter, finalize, map} from 'rxjs/operators';
import {InfiniteScroll} from '@common/core/ui/infinite-scroll/infinite.scroll';
import {WebPlayerState} from '../../web-player-state.service';

@Component({
    selector: 'notifications-page',
    templateUrl: './notifications-page.component.html',
    styleUrls: ['./notifications-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsPageComponent extends InfiniteScroll implements OnInit {
    public pagination$ = new BehaviorSubject<PaginationResponse<Notification>>(null);
    public loading$ = new BehaviorSubject<boolean>(false);
    public notificationType = NotificationType;

    constructor(
        private currentUser: CurrentUser,
        public urls: WebPlayerUrls,
        private notifications: NotificationsService,
        private route: ActivatedRoute,
        protected zone: NgZone,
        private state: WebPlayerState,
    ) {
        super();
        this.el = this.state.scrollContainer;
    }

    ngOnInit() {
        super.ngOnInit();
        this.pagination$.next(this.route.snapshot.data.api.pagination);
        this.pagination$
            .pipe(
                map(p => p.data.filter(n => !n.read_at)),
                filter(n => !!n.length)
            )
            .subscribe(unreadNotifications => {
                this.notifications.markAsRead(unreadNotifications.map(n => n.id)).subscribe(response => {
                    this.currentUser.model$.next({
                        ...this.currentUser.model$.value,
                        unread_notifications_count: response.unreadCount
                    });
                });
            });
    }

    public mediaUrl(media: Album|Track) {
        if (media.model_type === TRACK_MODEL) {
            return this.urls.track(media as Track);
        } else {
            return this.urls.album(media as Album);
        }
    }

    protected canLoadMore(): boolean {
        return this.pagination$.value.current_page < this.pagination$.value.last_page;
    }

    protected isLoading(): boolean {
        return this.loading$.value;
    }

    protected loadMoreItems() {
        this.loading$.next(true);
        const params = {
            userId: this.currentUser.get('id'),
            page: this.pagination$.value.current_page + 1
        };
        this.notifications.all(params)
            .pipe(finalize(() => this.loading$.next(false)))
            .subscribe(response => {
                this.pagination$.next({
                    ...response.pagination,
                    data: [...this.pagination$.value.data, ...response.pagination.data]
                });
            });
    }
}
