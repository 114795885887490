/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./channel-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../tracks/media-list-item/media-list-item.component.ngfactory";
import * as i3 from "../../../tracks/track-comments.service";
import * as i4 from "../../../../../common/shared/comments/comments.service";
import * as i5 from "../../../../../common/core/ui/toast.service";
import * as i6 from "../../../../../common/auth/current-user";
import * as i7 from "../../../player/player.service";
import * as i8 from "../../../tracks/tracks.service";
import * as i9 from "../../../tracks/media-list-item/media-list-item.component";
import * as i10 from "../../../../../common/core/ui/context-menu/context-menu.service";
import * as i11 from "../../../web-player-urls.service";
import * as i12 from "../../../../../common/core/config/settings.service";
import * as i13 from "@angular/common";
import * as i14 from "../../../tracks/track-list/track-list.component.ngfactory";
import * as i15 from "@angular/material/sort";
import * as i16 from "../../../tracks/track-list/selected-tracks.service";
import * as i17 from "../../../tracks/track-list/track-list.component";
import * as i18 from "../../../player/formatted-duration.service";
import * as i19 from "../../../../../common/core/services/browser-events.service";
import * as i20 from "../../../web-player-state.service";
import * as i21 from "../../../tracks/track-item/track-item.component.ngfactory";
import * as i22 from "../../../../../common/core/ui/context-menu/context-menu.directive";
import * as i23 from "../../../../../common/core/ui/context-menu/available-context-menus";
import * as i24 from "../../../tracks/track-item/track-item.component";
import * as i25 from "../../../media-grid/media-grid.component.ngfactory";
import * as i26 from "../../../media-grid/media-grid.component";
import * as i27 from "../../../../../common/core/translations/translate.directive";
import * as i28 from "../../../../../common/core/translations/translations.service";
import * as i29 from "@angular/router";
import * as i30 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i31 from "@angular/material/icon";
import * as i32 from "./channel-content.component";
import * as i33 from "../../../albums/album-item/album-item.component.ngfactory";
import * as i34 from "../../../albums/album-item/album-item.component";
import * as i35 from "../../../albums/albums.service";
import * as i36 from "../../../artists/artist-item/artist-item.component.ngfactory";
import * as i37 from "../../../artists/artist-item/artist-item.component";
import * as i38 from "../../../playlists/playlist-item/playlist-item.component.ngfactory";
import * as i39 from "../../../playlists/playlist-item/playlist-item.component";
import * as i40 from "../../../genres/genre-item/genre-item.component.ngfactory";
import * as i41 from "../../../genres/genre-item/genre-item.component";
var styles_ChannelContentComponent = [i0.styles];
var RenderType_ChannelContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChannelContentComponent, data: {} });
export { RenderType_ChannelContentComponent as RenderType_ChannelContentComponent };
function View_ChannelContentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "media-list-item", [], [[2, "disable-links", null]], null, null, i2.View_MediaListItemComponent_0, i2.RenderType_MediaListItemComponent)), i1.ɵprd(512, null, i3.TrackCommentsService, i3.TrackCommentsService, [i4.CommentsService, i5.Toast, i6.CurrentUser, i7.Player, i8.Tracks]), i1.ɵdid(2, 573440, null, 0, i9.MediaListItemComponent, [i7.Player, i10.ContextMenu, i1.ElementRef, i11.WebPlayerUrls, i12.Settings, i3.TrackCommentsService, i6.CurrentUser, i1.ChangeDetectorRef], { media: [0, "media"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).disableLinks; _ck(_v, 0, 0, currVal_0); }); }
function View_ChannelContentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "user-track-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_3)), i1.ɵdid(3, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.channel.content; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ChannelContentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "track-list", [["matSort", ""]], null, null, null, i14.View_TrackListComponent_0, i14.RenderType_TrackListComponent)), i1.ɵdid(2, 737280, [[1, 4]], 0, i15.MatSort, [], null, null), i1.ɵprd(512, null, i16.SelectedTracks, i16.SelectedTracks, []), i1.ɵdid(4, 4440064, null, 0, i17.TrackListComponent, [i7.Player, i18.FormattedDuration, i11.WebPlayerUrls, i10.ContextMenu, i1.NgZone, i1.ElementRef, i16.SelectedTracks, i19.BrowserEvents, i20.WebPlayerState, i1.ChangeDetectorRef], { dataSource: [0, "dataSource"], showArtist: [1, "showArtist"], showAlbum: [2, "showAlbum"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.trackTableSource; var currVal_1 = true; var currVal_2 = true; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ChannelContentComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "track-item", [["class", "media-grid-item"]], [[2, "active", null]], null, null, i21.View_TrackItemComponent_0, i21.RenderType_TrackItemComponent)), i1.ɵdid(1, 81920, null, 0, i22.ContextMenuDirective, [i1.ElementRef, i10.ContextMenu, i23.AVAILABLE_CONTEXT_MENUS], { params: [0, "params"] }, null), i1.ɵpod(2, { item: 0, type: 1 }), i1.ɵdid(3, 49152, null, 0, i24.TrackItemComponent, [i11.WebPlayerUrls, i7.Player], { track: [0, "track"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 2, 0, _v.context.$implicit, "track"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).playing(); _ck(_v, 0, 0, currVal_0); }); }
function View_ChannelContentComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "media-grid", [], [[2, "carousel", null]], null, null, i25.View_MediaGridComponent_0, i25.RenderType_MediaGridComponent)), i1.ɵdid(2, 4243456, null, 0, i26.MediaGridComponent, [i1.ElementRef, i1.ChangeDetectorRef], { carousel: [0, "carousel"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ChannelContentComponent_6)), i1.ɵdid(4, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.channel.layout === "carousel") && _co.nested); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.channel.content; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).carousel; _ck(_v, 1, 0, currVal_0); }); }
function View_ChannelContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i13.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_2)), i1.ɵdid(4, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_4)), i1.ɵdid(6, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_5)), i1.ɵdid(8, 16384, null, 0, i13.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.channel.layout; _ck(_v, 2, 0, currVal_0); var currVal_1 = "trackList"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "trackTable"; _ck(_v, 6, 0, currVal_2); }, null); }
function View_ChannelContentComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["trans", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 4341760, null, 0, i27.TranslateDirective, [i1.ElementRef, i28.Translations, i12.Settings], null, null), i1.ɵdid(3, 671744, null, 0, i29.RouterLinkWithHref, [i29.Router, i29.ActivatedRoute, i13.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 2), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "keyboard-arrow-right"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i30.View_MatIcon_0, i30.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i31.MatIcon, [i1.ElementRef, i31.MatIconRegistry, [8, null], [2, i31.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, "/channels", _v.parent.context.$implicit.slug); _ck(_v, 3, 0, currVal_2); var currVal_6 = "keyboard-arrow-right"; _ck(_v, 7, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.name; _ck(_v, 5, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 7).inline; var currVal_5 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_4, currVal_5); }); }
function View_ChannelContentComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_9)), i1.ɵdid(2, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "channel-content", [], null, null, null, View_ChannelContentComponent_0, RenderType_ChannelContentComponent)), i1.ɵdid(4, 4308992, null, 0, i32.ChannelContentComponent, [i11.WebPlayerUrls], { channel: [0, "channel"], nested: [1, "nested"] }, null)], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.hide_title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = true; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_ChannelContentComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "sub-channel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_8)), i1.ɵdid(2, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.channel.content; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ChannelContentComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "track-item", [["class", "media-grid-item"]], [[2, "active", null]], null, null, i21.View_TrackItemComponent_0, i21.RenderType_TrackItemComponent)), i1.ɵdid(1, 81920, null, 0, i22.ContextMenuDirective, [i1.ElementRef, i10.ContextMenu, i23.AVAILABLE_CONTEXT_MENUS], { params: [0, "params"] }, null), i1.ɵpod(2, { item: 0, type: 1 }), i1.ɵdid(3, 49152, null, 0, i24.TrackItemComponent, [i11.WebPlayerUrls, i7.Player], { track: [0, "track"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 2, 0, _v.parent.context.$implicit, "track"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).playing(); _ck(_v, 0, 0, currVal_0); }); }
function View_ChannelContentComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "album-item", [["class", "media-grid-item"]], [[2, "active", null]], null, null, i33.View_AlbumItemComponent_0, i33.RenderType_AlbumItemComponent)), i1.ɵdid(1, 81920, null, 0, i22.ContextMenuDirective, [i1.ElementRef, i10.ContextMenu, i23.AVAILABLE_CONTEXT_MENUS], { params: [0, "params"] }, null), i1.ɵpod(2, { item: 0, type: 1 }), i1.ɵdid(3, 49152, null, 0, i34.AlbumItemComponent, [i11.WebPlayerUrls, i35.Albums, i7.Player], { album: [0, "album"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 2, 0, _v.parent.context.$implicit, "album"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).playing(); _ck(_v, 0, 0, currVal_0); }); }
function View_ChannelContentComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "artist-item", [["class", "media-grid-item"]], [[2, "active", null]], null, null, i36.View_ArtistItemComponent_0, i36.RenderType_ArtistItemComponent)), i1.ɵdid(1, 81920, null, 0, i22.ContextMenuDirective, [i1.ElementRef, i10.ContextMenu, i23.AVAILABLE_CONTEXT_MENUS], { params: [0, "params"] }, null), i1.ɵpod(2, { item: 0, type: 1 }), i1.ɵdid(3, 49152, null, 0, i37.ArtistItemComponent, [i11.WebPlayerUrls, i7.Player], { artist: [0, "artist"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 2, 0, _v.parent.context.$implicit, "artist"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).playing(); _ck(_v, 0, 0, currVal_0); }); }
function View_ChannelContentComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "playlist-item", [["class", "media-grid-item"]], [[2, "active", null]], null, null, i38.View_PlaylistItemComponent_0, i38.RenderType_PlaylistItemComponent)), i1.ɵdid(1, 81920, null, 0, i22.ContextMenuDirective, [i1.ElementRef, i10.ContextMenu, i23.AVAILABLE_CONTEXT_MENUS], { params: [0, "params"] }, null), i1.ɵpod(2, { item: 0, type: 1 }), i1.ɵdid(3, 49152, null, 0, i39.PlaylistItemComponent, [i11.WebPlayerUrls, i7.Player], { playlist: [0, "playlist"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 2, 0, _v.parent.context.$implicit, "playlist"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).playing(); _ck(_v, 0, 0, currVal_0); }); }
function View_ChannelContentComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "genre-item", [["class", "media-grid-item"]], null, null, null, i40.View_GenreItemComponent_0, i40.RenderType_GenreItemComponent)), i1.ɵdid(1, 49152, null, 0, i41.GenreItemComponent, [i11.WebPlayerUrls], { genre: [0, "genre"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ChannelContentComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i13.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_12)), i1.ɵdid(4, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_13)), i1.ɵdid(6, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_14)), i1.ɵdid(8, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_15)), i1.ɵdid(10, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_16)), i1.ɵdid(12, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.model_type; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.modelTypes.track; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.modelTypes.album; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.modelTypes.mixedArtist; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.modelTypes.playlist; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.modelTypes.genre; _ck(_v, 12, 0, currVal_5); }, null); }
function View_ChannelContentComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "media-grid", [], [[2, "carousel", null]], null, null, i25.View_MediaGridComponent_0, i25.RenderType_MediaGridComponent)), i1.ɵdid(2, 4243456, null, 0, i26.MediaGridComponent, [i1.ElementRef, i1.ChangeDetectorRef], { carousel: [0, "carousel"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ChannelContentComponent_11)), i1.ɵdid(4, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.channel.layout === "carousel") && _co.nested); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.channel.content; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).carousel; _ck(_v, 1, 0, currVal_0); }); }
function View_ChannelContentComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "track-item", [["class", "media-grid-item"]], [[2, "active", null]], null, null, i21.View_TrackItemComponent_0, i21.RenderType_TrackItemComponent)), i1.ɵdid(1, 81920, null, 0, i22.ContextMenuDirective, [i1.ElementRef, i10.ContextMenu, i23.AVAILABLE_CONTEXT_MENUS], { params: [0, "params"] }, null), i1.ɵpod(2, { item: 0, type: 1 }), i1.ɵdid(3, 49152, null, 0, i24.TrackItemComponent, [i11.WebPlayerUrls, i7.Player], { track: [0, "track"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 2, 0, _v.parent.context.$implicit, "track"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).playing(); _ck(_v, 0, 0, currVal_0); }); }
function View_ChannelContentComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "album-item", [["class", "media-grid-item"]], [[2, "active", null]], null, null, i33.View_AlbumItemComponent_0, i33.RenderType_AlbumItemComponent)), i1.ɵdid(1, 81920, null, 0, i22.ContextMenuDirective, [i1.ElementRef, i10.ContextMenu, i23.AVAILABLE_CONTEXT_MENUS], { params: [0, "params"] }, null), i1.ɵpod(2, { item: 0, type: 1 }), i1.ɵdid(3, 49152, null, 0, i34.AlbumItemComponent, [i11.WebPlayerUrls, i35.Albums, i7.Player], { album: [0, "album"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 2, 0, _v.parent.context.$implicit, "album"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).playing(); _ck(_v, 0, 0, currVal_0); }); }
function View_ChannelContentComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "artist-item", [["class", "media-grid-item"]], [[2, "active", null]], null, null, i36.View_ArtistItemComponent_0, i36.RenderType_ArtistItemComponent)), i1.ɵdid(1, 81920, null, 0, i22.ContextMenuDirective, [i1.ElementRef, i10.ContextMenu, i23.AVAILABLE_CONTEXT_MENUS], { params: [0, "params"] }, null), i1.ɵpod(2, { item: 0, type: 1 }), i1.ɵdid(3, 49152, null, 0, i37.ArtistItemComponent, [i11.WebPlayerUrls, i7.Player], { artist: [0, "artist"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 2, 0, _v.parent.context.$implicit, "artist"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).playing(); _ck(_v, 0, 0, currVal_0); }); }
function View_ChannelContentComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "playlist-item", [["class", "media-grid-item"]], [[2, "active", null]], null, null, i38.View_PlaylistItemComponent_0, i38.RenderType_PlaylistItemComponent)), i1.ɵdid(1, 81920, null, 0, i22.ContextMenuDirective, [i1.ElementRef, i10.ContextMenu, i23.AVAILABLE_CONTEXT_MENUS], { params: [0, "params"] }, null), i1.ɵpod(2, { item: 0, type: 1 }), i1.ɵdid(3, 49152, null, 0, i39.PlaylistItemComponent, [i11.WebPlayerUrls, i7.Player], { playlist: [0, "playlist"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 2, 0, _v.parent.context.$implicit, "playlist"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).playing(); _ck(_v, 0, 0, currVal_0); }); }
function View_ChannelContentComponent_23(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "genre-item", [["class", "media-grid-item"]], null, null, null, i40.View_GenreItemComponent_0, i40.RenderType_GenreItemComponent)), i1.ɵdid(1, 49152, null, 0, i41.GenreItemComponent, [i11.WebPlayerUrls], { genre: [0, "genre"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ChannelContentComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i13.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_19)), i1.ɵdid(4, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_20)), i1.ɵdid(6, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_21)), i1.ɵdid(8, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_22)), i1.ɵdid(10, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_23)), i1.ɵdid(12, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.model_type; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.modelTypes.track; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.modelTypes.album; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.modelTypes.mixedArtist; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.modelTypes.playlist; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.modelTypes.genre; _ck(_v, 12, 0, currVal_5); }, null); }
function View_ChannelContentComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "media-grid", [], [[2, "carousel", null]], null, null, i25.View_MediaGridComponent_0, i25.RenderType_MediaGridComponent)), i1.ɵdid(2, 4243456, null, 0, i26.MediaGridComponent, [i1.ElementRef, i1.ChangeDetectorRef], { carousel: [0, "carousel"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ChannelContentComponent_18)), i1.ɵdid(4, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.channel.layout === "carousel") && _co.nested); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.channel.content; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).carousel; _ck(_v, 1, 0, currVal_0); }); }
function View_ChannelContentComponent_24(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "no-results-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "album"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i30.View_MatIcon_0, i30.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i31.MatIcon, [i1.ElementRef, i31.MatIconRegistry, [8, null], [2, i31.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "main"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(5, 4341760, null, 0, i27.TranslateDirective, [i1.ElementRef, i28.Translations, i12.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Nothing to display."])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "secondary"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(8, 4341760, null, 0, i27.TranslateDirective, [i1.ElementRef, i28.Translations, i12.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Seems like this channel does not have any content yet."]))], function (_ck, _v) { var currVal_2 = "album"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ChannelContentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { matSort: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i13.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_1)), i1.ɵdid(4, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_7)), i1.ɵdid(6, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_10)), i1.ɵdid(8, 278528, null, 0, i13.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_17)), i1.ɵdid(10, 16384, null, 0, i13.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i13.NgSwitch], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelContentComponent_24)), i1.ɵdid(12, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.channel.content_type; _ck(_v, 2, 0, currVal_0); var currVal_1 = "track"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "channel"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "mixed"; _ck(_v, 8, 0, currVal_3); var currVal_4 = (!((_co.channel.content == null) ? null : _co.channel.content.length) && !_co.nested); _ck(_v, 12, 0, currVal_4); }, null); }
export function View_ChannelContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "channel-content", [], null, null, null, View_ChannelContentComponent_0, RenderType_ChannelContentComponent)), i1.ɵdid(1, 4308992, null, 0, i32.ChannelContentComponent, [i11.WebPlayerUrls], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChannelContentComponentNgFactory = i1.ɵccf("channel-content", i32.ChannelContentComponent, View_ChannelContentComponent_Host_0, { channel: "channel", nested: "nested" }, {}, []);
export { ChannelContentComponentNgFactory as ChannelContentComponentNgFactory };
