/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notifications-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../common/core/translations/translate.directive";
import * as i5 from "../../../../common/core/translations/translations.service";
import * as i6 from "../../../../common/core/config/settings.service";
import * as i7 from "../../shared/media-image/media-image.component.ngfactory";
import * as i8 from "../../shared/media-image/media-image.component";
import * as i9 from "@angular/router";
import * as i10 from "@angular/common";
import * as i11 from "../../../../common/core/ui/loading-indicator/loading-indicator.component.ngfactory";
import * as i12 from "../../../../common/core/ui/loading-indicator/loading-indicator.component";
import * as i13 from "../../../../common/core/translations/translate.pipe";
import * as i14 from "./notifications-page.component";
import * as i15 from "../../../../common/auth/current-user";
import * as i16 from "../../web-player-urls.service";
import * as i17 from "../notifications.service";
import * as i18 from "../../web-player-state.service";
var styles_NotificationsPageComponent = [i0.styles];
var RenderType_NotificationsPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationsPageComponent, data: {} });
export { RenderType_NotificationsPageComponent as RenderType_NotificationsPageComponent };
function View_NotificationsPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "no-results-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "notifications"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "main"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(5, 4341760, null, 0, i4.TranslateDirective, [i1.ElementRef, i5.Translations, i6.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Hang tight!"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "secondary"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(8, 4341760, null, 0, i4.TranslateDirective, [i1.ElementRef, i5.Translations, i6.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Notifications will start showing up here soon."]))], function (_ck, _v) { var currVal_2 = "notifications"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_NotificationsPageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "media-image", [], null, null, null, i7.View_MediaImageComponent_0, i7.RenderType_MediaImageComponent)), i1.ɵdid(2, 573440, null, 0, i8.MediaImageComponent, [i6.Settings], { media: [0, "media"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "middle-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["class", "username"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i9.RouterLinkWithHref, [i9.Router, i9.ActivatedRoute, i10.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "comment"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(9, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "quote"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 5, "div", [["class", "track"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), i1.ɵppd(14, 1), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i9.RouterLinkWithHref, [i9.Router, i9.ActivatedRoute, i10.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(17, null, ["", ""])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "div", [["class", "right-column"]], null, null, null, null, null)), (_l()(), i1.ɵted(19, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.data.user; _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.urls.user(_v.parent.context.$implicit.data.user); _ck(_v, 5, 0, currVal_3); var currVal_7 = "comment"; _ck(_v, 9, 0, currVal_7); var currVal_12 = _co.urls.track(_v.parent.context.$implicit.data.track); _ck(_v, 16, 0, currVal_12); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 5).target; var currVal_2 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_4 = _v.parent.context.$implicit.data.user.display_name; _ck(_v, 6, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 9).inline; var currVal_6 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 8, 0, currVal_5, currVal_6); var currVal_8 = _v.parent.context.$implicit.data.comment_content; _ck(_v, 11, 0, currVal_8); var currVal_9 = i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), "on")); _ck(_v, 13, 0, currVal_9); var currVal_10 = i1.ɵnov(_v, 16).target; var currVal_11 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_10, currVal_11); var currVal_13 = _v.parent.context.$implicit.data.track.name; _ck(_v, 17, 0, currVal_13); var currVal_14 = _v.parent.context.$implicit.relative_created_at; _ck(_v, 19, 0, currVal_14); }); }
function View_NotificationsPageComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "media-image", [["default", "album"]], null, null, null, i7.View_MediaImageComponent_0, i7.RenderType_MediaImageComponent)), i1.ɵdid(2, 573440, null, 0, i8.MediaImageComponent, [i6.Settings], { media: [0, "media"], default: [1, "default"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "middle-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["class", "username"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i9.RouterLinkWithHref, [i9.Router, i9.ActivatedRoute, i10.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "play-arrow"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(9, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "a", [["class", "quote"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i9.RouterLinkWithHref, [i9.Router, i9.ActivatedRoute, i10.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "track"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(14, 4341760, null, 0, i4.TranslateDirective, [i1.ElementRef, i5.Translations, i6.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["New upload"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "right-column"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.data.media; var currVal_1 = "album"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _co.urls.user(_v.parent.context.$implicit.data.artist); _ck(_v, 5, 0, currVal_4); var currVal_8 = "play-arrow"; _ck(_v, 9, 0, currVal_8); var currVal_11 = _co.mediaUrl(_v.parent.context.$implicit.data.media); _ck(_v, 11, 0, currVal_11); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 5).target; var currVal_3 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_5 = _v.parent.context.$implicit.data.artist.name; _ck(_v, 6, 0, currVal_5); var currVal_6 = i1.ɵnov(_v, 9).inline; var currVal_7 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 8, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 11).target; var currVal_10 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_9, currVal_10); var currVal_12 = _v.parent.context.$implicit.data.media.name; _ck(_v, 12, 0, currVal_12); var currVal_13 = _v.parent.context.$implicit.relative_created_at; _ck(_v, 17, 0, currVal_13); }); }
function View_NotificationsPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [["class", "notification"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i10.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsPageComponent_5)), i1.ɵdid(4, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsPageComponent_6)), i1.ɵdid(6, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.type; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.notificationType.commentReply; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.notificationType.newUpload; _ck(_v, 6, 0, currVal_2); }, null); }
function View_NotificationsPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(2, 4341760, null, 0, i4.TranslateDirective, [i1.ElementRef, i5.Translations, i6.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Notifications"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsPageComponent_4)), i1.ɵdid(6, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "loading-indicator", [["class", "inline"]], [[40, "@visibility", 0]], null, null, i11.View_LoadingIndicatorComponent_0, i11.RenderType_LoadingIndicatorComponent)), i1.ɵdid(8, 49152, null, 0, i12.LoadingIndicatorComponent, [], { isVisible: [0, "isVisible"] }, null), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.ngIf.data; _ck(_v, 6, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.loading$)); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 8).isVisible; _ck(_v, 7, 0, currVal_1); }); }
function View_NotificationsPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsPageComponent_2)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsPageComponent_3)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.ngIf.data.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.ngIf.data.length; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_NotificationsPageComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i13.TransPipe, [i5.Translations]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "notifications-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NotificationsPageComponent_1)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.pagination$)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_NotificationsPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "notifications-page", [], null, null, null, View_NotificationsPageComponent_0, RenderType_NotificationsPageComponent)), i1.ɵdid(1, 245760, null, 0, i14.NotificationsPageComponent, [i15.CurrentUser, i16.WebPlayerUrls, i17.NotificationsService, i9.ActivatedRoute, i1.NgZone, i18.WebPlayerState], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationsPageComponentNgFactory = i1.ɵccf("notifications-page", i14.NotificationsPageComponent, View_NotificationsPageComponent_Host_0, { threshold: "threshold" }, {}, []);
export { NotificationsPageComponentNgFactory as NotificationsPageComponentNgFactory };
