import * as tslib_1 from "tslib";
import { WebPlayerUrls } from '../../web-player-urls.service';
import { Player } from '../../player/player.service';
import { queueId } from '../../player/queue-id';
import { Track } from '../../../models/Track';
export class TrackItemComponent {
    constructor(urls, player) {
        this.urls = urls;
        this.player = player;
    }
    playing() {
        return this.player.mediaItemPlaying(this.queueId());
    }
    play() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.player.playMediaItem(this.queueId(), [this.track]);
        });
    }
    pause() {
        this.player.pause();
    }
    queueId() {
        return queueId(this.track, 'allTracks');
    }
}
