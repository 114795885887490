import { TRACK_MODEL } from '../../../models/Track';
import { AppHttpClient } from 'common/core/http/app-http-client.service';
import { ARTIST_MODEL } from '../../../models/Artist';
import { ALBUM_MODEL } from '../../../models/Album';
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/core/http/app-http-client.service";
export class UserLibrary {
    constructor(http) {
        this.http = http;
        this.likes = {
            [TRACK_MODEL]: {},
            [ALBUM_MODEL]: {},
            [ARTIST_MODEL]: {},
        };
    }
    setLikes(likeables) {
        this.likes = Object.assign({}, this.likes, likeables);
    }
    add(likeables) {
        return new Promise((resolve, reject) => {
            const payload = likeables
                .filter(likeable => !this.has(likeable))
                .map(likeable => {
                return { likeable_id: likeable.id, likeable_type: likeable.model_type };
            });
            this.http.post('user/likeables', { likeables: payload }).subscribe(() => {
                payload.forEach(like => {
                    this.likes[like.likeable_type][like.likeable_id] = true;
                });
                resolve(likeables);
            }, () => reject());
        });
    }
    remove(likeables) {
        return new Promise(((resolve, reject) => {
            const payload = likeables.map(track => {
                return { likeable_id: track.id, likeable_type: track.model_type };
            });
            this.http.delete('user/likeables', { likeables: payload }).subscribe(() => {
                payload.forEach(like => {
                    delete this.likes[like.likeable_type][like.likeable_id];
                });
                resolve(likeables);
            }, () => reject());
        }));
    }
    has(likeable) {
        return this.likes[likeable.model_type] && this.likes[likeable.model_type][likeable.id];
    }
    count(modelType) {
        return Object.keys(this.likes[modelType]).length;
    }
}
UserLibrary.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserLibrary_Factory() { return new UserLibrary(i0.ɵɵinject(i1.AppHttpClient)); }, token: UserLibrary, providedIn: "root" });
