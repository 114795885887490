/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./track-embed.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../media-list-item/media-list-item.component.ngfactory";
import * as i3 from "../track-comments.service";
import * as i4 from "../../../../common/shared/comments/comments.service";
import * as i5 from "../../../../common/core/ui/toast.service";
import * as i6 from "../../../../common/auth/current-user";
import * as i7 from "../../player/player.service";
import * as i8 from "../tracks.service";
import * as i9 from "../media-list-item/media-list-item.component";
import * as i10 from "../../../../common/core/ui/context-menu/context-menu.service";
import * as i11 from "../../web-player-urls.service";
import * as i12 from "../../../../common/core/config/settings.service";
import * as i13 from "../../web-player-state.service";
import * as i14 from "./track-embed.component";
import * as i15 from "@angular/router";
import * as i16 from "../../player/track-plays.service";
var styles_TrackEmbedComponent = [i0.styles];
var RenderType_TrackEmbedComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TrackEmbedComponent, data: {} });
export { RenderType_TrackEmbedComponent as RenderType_TrackEmbedComponent };
export function View_TrackEmbedComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { mediaListItem: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "media-list-item", [], [[2, "disable-links", null]], null, null, i2.View_MediaListItemComponent_0, i2.RenderType_MediaListItemComponent)), i1.ɵprd(512, null, i3.TrackCommentsService, i3.TrackCommentsService, [i4.CommentsService, i5.Toast, i6.CurrentUser, i7.Player, i8.Tracks]), i1.ɵdid(3, 573440, [[1, 4]], 0, i9.MediaListItemComponent, [i7.Player, i10.ContextMenu, i1.ElementRef, i11.WebPlayerUrls, i12.Settings, i3.TrackCommentsService, i6.CurrentUser, i1.ChangeDetectorRef], { media: [0, "media"], showArtwork: [1, "showArtwork"], showActions: [2, "showActions"], disableLinks: [3, "disableLinks"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "div", [["class", "track-artwork"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "player-container youtube-player"], ["id", "youtube-player"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "player-container html5-player"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "player-container soundcloud-player"]], [[2, "active", null]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.media; var currVal_2 = false; var currVal_3 = false; var currVal_4 = true; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).disableLinks; _ck(_v, 1, 0, currVal_0); var currVal_5 = (_co.player.getPlaybackStrategy() === "youtube"); _ck(_v, 5, 0, currVal_5); var currVal_6 = (_co.player.getPlaybackStrategy() === "html5"); _ck(_v, 6, 0, currVal_6); var currVal_7 = (_co.player.getPlaybackStrategy() === "soundcloud"); _ck(_v, 7, 0, currVal_7); }); }
export function View_TrackEmbedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "track-embed", [], null, null, null, View_TrackEmbedComponent_0, RenderType_TrackEmbedComponent)), i1.ɵprd(512, null, i13.WebPlayerState, i13.WebPlayerState, []), i1.ɵdid(2, 114688, null, 0, i14.TrackEmbedComponent, [i15.ActivatedRoute, i13.WebPlayerState, i1.ElementRef, i7.Player, i16.TrackPlays], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var TrackEmbedComponentNgFactory = i1.ɵccf("track-embed", i14.TrackEmbedComponent, View_TrackEmbedComponent_Host_0, {}, {}, []);
export { TrackEmbedComponentNgFactory as TrackEmbedComponentNgFactory };
