/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./queue-sidebar.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/core/ui/context-menu/context-menu.directive";
import * as i3 from "../../../common/core/ui/context-menu/context-menu.service";
import * as i4 from "../../../common/core/ui/context-menu/available-context-menus";
import * as i5 from "../shared/media-image/media-image.component.ngfactory";
import * as i6 from "../shared/media-image/media-image.component";
import * as i7 from "../../../common/core/config/settings.service";
import * as i8 from "../player/playback-control-button/playback-control-button.component.ngfactory";
import * as i9 from "../player/playback-control-button/playback-control-button.component";
import * as i10 from "../player/player.service";
import * as i11 from "../artists/artists-links-list/artists-links-list.component.ngfactory";
import * as i12 from "../artists/artists-links-list/artists-links-list.component";
import * as i13 from "../web-player-urls.service";
import * as i14 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i15 from "@angular/material/icon";
import * as i16 from "../../../common/core/ui/custom-scrollbar/custom-scrollbar.directive";
import * as i17 from "../../../common/core/ui/breakpoints.service";
import * as i18 from "@angular/cdk/observers";
import * as i19 from "../../../common/core/ui/custom-scrollbar/supports-native-scrollbar-styling";
import * as i20 from "@angular/common";
import * as i21 from "./queue-sidebar.component";
import * as i22 from "../player/player-queue.service";
import * as i23 from "./queue-sidebar.service";
import * as i24 from "../web-player-images.service";
var styles_QueueSidebarComponent = [i0.styles];
var RenderType_QueueSidebarComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_QueueSidebarComponent, data: {} });
export { RenderType_QueueSidebarComponent as RenderType_QueueSidebarComponent };
function View_QueueSidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "queue-item"]], [[2, "active", null]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.ContextMenuDirective, [i1.ElementRef, i3.ContextMenu, i4.AVAILABLE_CONTEXT_MENUS], { params: [0, "params"] }, null), i1.ɵpod(2, { item: 0, type: 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "img-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "media-image", [], null, null, null, i5.View_MediaImageComponent_0, i5.RenderType_MediaImageComponent)), i1.ɵdid(5, 573440, null, 0, i6.MediaImageComponent, [i7.Settings], { media: [0, "media"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "playback-control-button", [], null, [[null, "play"], [null, "pause"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("play" === en)) {
        var pd_0 = (_co.playTrack(_v.context.$implicit, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("pause" === en)) {
        var pd_1 = (_co.player.pause() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_PlaybackControlButtonComponent_0, i8.RenderType_PlaybackControlButtonComponent)), i1.ɵdid(7, 49152, null, 0, i9.PlaybackControlButtonComponent, [i10.Player], { track: [0, "track"] }, { play: "play", pause: "pause" }), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "meta"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "track-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "artists-links-list", [["class", "artist-name"]], null, null, null, i11.View_ArtistsLinksListComponent_0, i11.RenderType_ArtistsLinksListComponent)), i1.ɵdid(12, 573440, null, 0, i12.ArtistsLinksListComponent, [i13.WebPlayerUrls], { track: [0, "track"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "no-style toggle-menu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showContextMenu(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "more-horiz"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(15, 9158656, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 2, 0, _v.context.$implicit, "track"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit; _ck(_v, 7, 0, currVal_3); var currVal_5 = _v.context.$implicit; _ck(_v, 12, 0, currVal_5); var currVal_8 = "more-horiz"; _ck(_v, 15, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trackIsPlaying(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_4 = _v.context.$implicit.name; _ck(_v, 10, 0, currVal_4); var currVal_6 = i1.ɵnov(_v, 15).inline; var currVal_7 = (((i1.ɵnov(_v, 15).color !== "primary") && (i1.ɵnov(_v, 15).color !== "accent")) && (i1.ɵnov(_v, 15).color !== "warn")); _ck(_v, 14, 0, currVal_6, currVal_7); }); }
export function View_QueueSidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "scroll-wrapper"], ["customScrollbar", ""]], [[2, "full-height", null]], null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i16.CustomScrollbarDirective, [i1.ElementRef, i1.Renderer2, i17.BreakpointsService, i1.NgZone, i18.MutationObserverFactory, i7.Settings, i19.SUPPORTS_NATIVE_SCROLLBAR_STYLING], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueueSidebarComponent_1)), i1.ɵdid(3, 278528, null, 0, i20.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.queue.getAll(); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoIsHidden; _ck(_v, 0, 0, currVal_0); }); }
export function View_QueueSidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "queue-sidebar", [], [[2, "hide", null]], null, null, View_QueueSidebarComponent_0, RenderType_QueueSidebarComponent)), i1.ɵdid(1, 114688, null, 0, i21.QueueSidebarComponent, [i22.PlayerQueue, i10.Player, i23.QueueSidebar, i3.ContextMenu, i7.Settings, i24.WebPlayerImagesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = !i1.ɵnov(_v, 1).sidebar.isVisible(); _ck(_v, 0, 0, currVal_0); }); }
var QueueSidebarComponentNgFactory = i1.ɵccf("queue-sidebar", i21.QueueSidebarComponent, View_QueueSidebarComponent_Host_0, {}, {}, []);
export { QueueSidebarComponentNgFactory as QueueSidebarComponentNgFactory };
