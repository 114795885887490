/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./channel-fallback-host.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../channel-show/channel-show.component.ngfactory";
import * as i3 from "../channel-show/channel-show.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../common/core/pages/shared/not-found-page/not-found-page.component.ngfactory";
import * as i6 from "../../../../common/core/pages/shared/not-found-page/not-found-page.component";
import * as i7 from "../../../../common/core/config/settings.service";
import * as i8 from "@angular/common";
import * as i9 from "./channel-fallback-host.component";
var styles_ChannelFallbackHostComponent = [i0.styles];
var RenderType_ChannelFallbackHostComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChannelFallbackHostComponent, data: {} });
export { RenderType_ChannelFallbackHostComponent as RenderType_ChannelFallbackHostComponent };
function View_ChannelFallbackHostComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "channel-show", [], null, null, null, i2.View_ChannelShowComponent_0, i2.RenderType_ChannelShowComponent)), i1.ɵdid(1, 114688, null, 0, i3.ChannelShowComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ChannelFallbackHostComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "not-found-page", [], null, null, null, i5.View_NotFoundPageComponent_0, i5.RenderType_NotFoundPageComponent)), i1.ɵdid(1, 49152, null, 0, i6.NotFoundPageComponent, [i7.Settings], null, null)], null, null); }
export function View_ChannelFallbackHostComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChannelFallbackHostComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["notFoundPage", 2]], null, 0, null, View_ChannelFallbackHostComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.channel$)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ChannelFallbackHostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "channel-fallback-host", [], null, null, null, View_ChannelFallbackHostComponent_0, RenderType_ChannelFallbackHostComponent)), i1.ɵdid(1, 114688, null, 0, i9.ChannelFallbackHostComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChannelFallbackHostComponentNgFactory = i1.ɵccf("channel-fallback-host", i9.ChannelFallbackHostComponent, View_ChannelFallbackHostComponent_Host_0, {}, {}, []);
export { ChannelFallbackHostComponentNgFactory as ChannelFallbackHostComponentNgFactory };
