import * as tslib_1 from "tslib";
import { WebPlayerUrls } from '../../web-player-urls.service';
import { Album } from '../../../models/Album';
import { Albums } from '../albums.service';
import { Player } from '../../player/player.service';
import { queueId } from '../../player/queue-id';
export class AlbumItemComponent {
    constructor(urls, albums, player) {
        this.urls = urls;
        this.albums = albums;
        this.player = player;
    }
    playing() {
        return this.player.mediaItemPlaying(this.queueId());
    }
    play() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.player.playMediaItem(this.queueId());
        });
    }
    pause() {
        this.player.pause();
    }
    queueId() {
        return queueId(this.album, 'allTracks');
    }
}
