import { Router } from '@angular/router';
import { Search } from '../search.service';
import { WebPlayerState } from '../../web-player-state.service';
import * as i0 from "@angular/core";
import * as i1 from "../search.service";
import * as i2 from "@angular/router";
import * as i3 from "../../web-player-state.service";
export class SearchResolver {
    constructor(search, router, state) {
        this.search = search;
        this.router = router;
        this.state = state;
        this.lastSearch = { query: '', results: {} };
    }
    resolve(route, state) {
        this.state.loading = true;
        const query = route.paramMap.get('query');
        if (this.lastSearch.query === query) {
            this.state.loading = false;
            return new Promise(resolve => resolve(this.lastSearch.results));
        }
        return this.search.everything(query, { limit: 20 }).toPromise().then(response => {
            this.state.loading = false;
            if (response.results) {
                this.lastSearch = response;
                return response.results;
            }
            else {
                this.router.navigate(['/']);
                return null;
            }
        }).catch(() => {
            this.state.loading = false;
            this.router.navigate(['/']);
        });
    }
}
SearchResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchResolver_Factory() { return new SearchResolver(i0.ɵɵinject(i1.Search), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.WebPlayerState)); }, token: SearchResolver, providedIn: "root" });
