/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./track-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/media-image/media-image.component.ngfactory";
import * as i3 from "../../shared/media-image/media-image.component";
import * as i4 from "../../../../common/core/config/settings.service";
import * as i5 from "../../player/playback-control-button/playback-control-button.component.ngfactory";
import * as i6 from "../../player/playback-control-button/playback-control-button.component";
import * as i7 from "../../player/player.service";
import * as i8 from "@angular/router";
import * as i9 from "@angular/common";
import * as i10 from "../../artists/artists-links-list/artists-links-list.component.ngfactory";
import * as i11 from "../../artists/artists-links-list/artists-links-list.component";
import * as i12 from "../../web-player-urls.service";
import * as i13 from "./track-item.component";
var styles_TrackItemComponent = [i0.styles];
var RenderType_TrackItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TrackItemComponent, data: {} });
export { RenderType_TrackItemComponent as RenderType_TrackItemComponent };
export function View_TrackItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "img-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "media-image", [["default", "track"]], null, null, null, i2.View_MediaImageComponent_0, i2.RenderType_MediaImageComponent)), i1.ɵdid(2, 573440, null, 0, i3.MediaImageComponent, [i4.Settings], { media: [0, "media"], default: [1, "default"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "playback-control-button", [], null, [[null, "play"], [null, "pause"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("play" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } if (("pause" === en)) {
        var pd_1 = (_co.pause() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_PlaybackControlButtonComponent_0, i5.RenderType_PlaybackControlButtonComponent)), i1.ɵdid(4, 49152, null, 0, i6.PlaybackControlButtonComponent, [i7.Player], { playing: [0, "playing"] }, { play: "play", pause: "pause" }), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["class", "track-name primary-text"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i8.RouterLinkWithHref, [i8.Router, i8.ActivatedRoute, i9.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "artists-links-list", [["class", "artist-name secondary-text"]], null, null, null, i10.View_ArtistsLinksListComponent_0, i10.RenderType_ArtistsLinksListComponent)), i1.ɵdid(10, 573440, null, 0, i11.ArtistsLinksListComponent, [i12.WebPlayerUrls], { track: [0, "track"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.track; var currVal_1 = "track"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.playing(); _ck(_v, 4, 0, currVal_2); var currVal_5 = _co.urls.track(_co.track); _ck(_v, 7, 0, currVal_5); var currVal_7 = _co.track; _ck(_v, 10, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵnov(_v, 7).target; var currVal_4 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_6 = _co.track.name; _ck(_v, 8, 0, currVal_6); }); }
export function View_TrackItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "track-item", [["class", "media-grid-item"]], [[2, "active", null]], null, null, View_TrackItemComponent_0, RenderType_TrackItemComponent)), i1.ɵdid(1, 49152, null, 0, i13.TrackItemComponent, [i12.WebPlayerUrls, i7.Player], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).playing(); _ck(_v, 0, 0, currVal_0); }); }
var TrackItemComponentNgFactory = i1.ɵccf("track-item", i13.TrackItemComponent, View_TrackItemComponent_Host_0, { track: "track" }, {}, []);
export { TrackItemComponentNgFactory as TrackItemComponentNgFactory };
