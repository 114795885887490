import { Router } from '@angular/router';
import { Artists } from '../artists.service';
import { Player } from '../../player/player.service';
import { WebPlayerState } from '../../web-player-state.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../artists.service";
import * as i2 from "../../player/player.service";
import * as i3 from "../../web-player-state.service";
import * as i4 from "@angular/router";
export class ArtistResolver {
    constructor(artists, player, state, router) {
        this.artists = artists;
        this.player = player;
        this.state = state;
        this.router = router;
    }
    resolve(route, state) {
        const params = { with: ['similar', 'genres', 'bio', 'bioImages'], top_tracks: true };
        this.state.loading = true;
        const id = +route.paramMap.get('id');
        return this.artists.get(id, params).pipe(catchError(() => {
            this.state.loading = false;
            this.router.navigate(['/']);
            return EMPTY;
        }), mergeMap(response => {
            this.state.loading = false;
            if (response.artist) {
                return of(response);
            }
            else {
                this.router.navigate(['/']);
                return EMPTY;
            }
        }));
    }
}
ArtistResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ArtistResolver_Factory() { return new ArtistResolver(i0.ɵɵinject(i1.Artists), i0.ɵɵinject(i2.Player), i0.ɵɵinject(i3.WebPlayerState), i0.ɵɵinject(i4.Router)); }, token: ArtistResolver, providedIn: "root" });
