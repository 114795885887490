import * as tslib_1 from "tslib";
import { PlayerState } from '../player-state.service';
import { NgZone } from '@angular/core';
import { PlayerQueue } from '../player-queue.service';
import { Search } from '../../search/search.service';
import * as Dot from 'dot-object';
import { LazyLoaderService } from 'common/core/utils/lazy-loader.service';
import * as i0 from "@angular/core";
import * as i1 from "../player-state.service";
import * as i2 from "../player-queue.service";
import * as i3 from "../../search/search.service";
import * as i4 from "../../../../common/core/utils/lazy-loader.service";
export class SoundcloudStrategy {
    /**
     * Html5Strategy Constructor.
     */
    constructor(state, zone, queue, search, lazyLoader) {
        this.state = state;
        this.zone = zone;
        this.queue = queue;
        this.search = search;
        this.lazyLoader = lazyLoader;
        /**
         * Whether player is already bootstrapped.
         */
        this.bootstrapped = false;
        /**
         * Volume that should be set after player is bootstrapped.
         */
        this.pendingVolume = null;
        /**
         * Loaded track duration in seconds.
         */
        this.duration = 0;
        /**
         * Elapsed time in seconds since the track started playing.
         */
        this.position = 0;
    }
    /**
     * Start playback.
     */
    play() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.cueTrack(this.queue.getCurrent());
            this.soundcloud.play();
            this.state.playing = true;
        });
    }
    /**
     * Pause playback.
     */
    pause() {
        this.soundcloud.pause();
        this.state.playing = false;
    }
    /**
     * Stop playback.
     */
    stop() {
        this.pause();
        this.seekTo(0);
        this.state.playing = false;
    }
    /**
     * Seek to specified time in track.
     */
    seekTo(time) {
        this.soundcloud.seekTo(time * 1000);
    }
    /**
     * Get loaded track duration in seconds.
     */
    getDuration() {
        return this.duration / 1000;
    }
    /**
     * Get elapsed time in seconds since the track started playing.
     */
    getCurrentTime() {
        return this.position / 1000;
    }
    /**
     * Set soundcloud player volume.
     */
    setVolume(number) {
        if (!this.soundcloud) {
            this.pendingVolume = number;
        }
        else {
            this.soundcloud.setVolume(number);
        }
    }
    /**
     * Mute the player.
     */
    mute() {
        const currentVol = this.soundcloud.getVolume();
        this.pendingVolume = typeof currentVol === 'number' ? currentVol : this.pendingVolume;
        this.soundcloud.setVolume(0);
    }
    /**
     * Unmute the player.
     */
    unMute() {
        const volume = this.pendingVolume || 50;
        this.soundcloud.setVolume(volume);
    }
    /**
     * Get track that is currently cued for playback.
     */
    getCuedTrack() {
        return this.cuedTrack;
    }
    /**
     * Check if youtube player is ready.
     */
    ready() {
        return this.bootstrapped;
    }
    /**
     * Fetch youtube ID for specified track if needed and cue it in youtube player.
     */
    cueTrack(track) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.cueing === track || this.cuedTrack === track)
                return;
            this.cueing = track;
            this.state.buffering = true;
            if (!track.youtube_id) {
                const artist = Dot.pick('album.artist.name', track) || track.artists[0];
                const results = yield this.search.videoId(artist, track).toPromise();
                track.youtube_id = results[0].id;
            }
            return new Promise(resolve => {
                this.bootstrap(track).then(() => {
                    this.soundcloud.load(track.youtube_id, this.getPlayerVars(resolve));
                    this.cuedTrack = track;
                    this.cueing = null;
                });
            });
        });
    }
    /**
     * Destroy soundcloud playback strategy.
     */
    destroy() {
        this.soundcloud && this.soundcloud.remove && this.soundcloud.remove();
        this.soundcloud = null;
        this.bootstrapped = false;
        this.cuedTrack = null;
    }
    /**
     * Bootstrap soundcloud player.
     */
    bootstrap(track) {
        if (this.bootstrapped)
            return new Promise(resolve => resolve());
        return new Promise(resolve => {
            this.lazyLoader.loadAsset('https://w.soundcloud.com/player/api.js', { type: 'js' }).then(() => {
                const iframe = document.createElement('iframe');
                iframe.onload = () => {
                    this.soundcloud = SC.Widget(iframe);
                    this.handlePlayerStateChangeEvents();
                    resolve();
                };
                iframe.id = 'soundcloud-iframe';
                iframe.src = 'https://w.soundcloud.com/player/?url=' + track.youtube_id + '&color=0066cc';
                document.querySelector('.soundcloud-player').appendChild(iframe);
            });
        });
    }
    /**
     * Handle soundcloud playback state change events.
     */
    handlePlayerStateChangeEvents() {
        this.soundcloud.bind(SC.Widget.Events.PLAY, () => {
            this.setState('playing', true);
        });
        this.soundcloud.bind(SC.Widget.Events.PAUSE, () => {
            this.setState('playing', false);
        });
        this.soundcloud.bind(SC.Widget.Events.PLAY_PROGRESS, (e) => {
            this.position = e.currentPosition;
        });
        this.soundcloud.bind(SC.Widget.Events.ERROR, () => {
            this.cuedTrack = null;
            this.setState('playing', false);
            this.state.firePlaybackEnded();
        });
        this.soundcloud.bind(SC.Widget.Events.FINISH, () => {
            this.state.firePlaybackEnded();
            this.setState('playing', false);
        });
    }
    /**
     * Set specified player state.
     */
    setState(name, value) {
        this.zone.run(() => this.state[name] = value);
    }
    /**
     * Handle soundcloud player ready event.
     */
    handlePlayerReadyEvent(resolve) {
        if (this.state.muted)
            this.mute();
        this.bootstrapped = true;
        resolve && resolve();
        this.state.fireReadyEvent();
        this.soundcloud.getDuration(duration => {
            this.duration = duration;
        });
        if (this.pendingVolume) {
            this.setVolume(this.pendingVolume);
            this.pendingVolume = null;
        }
    }
    /**
     * Get soundcloud player options.
     */
    getPlayerVars(resolve) {
        return {
            buying: false,
            liking: false,
            download: false,
            sharing: false,
            show_artwork: false,
            show_comments: false,
            show_playcount: false,
            show_user: false,
            callback: () => {
                this.handlePlayerReadyEvent(resolve);
            }
        };
    }
}
SoundcloudStrategy.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SoundcloudStrategy_Factory() { return new SoundcloudStrategy(i0.ɵɵinject(i1.PlayerState), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i2.PlayerQueue), i0.ɵɵinject(i3.Search), i0.ɵɵinject(i4.LazyLoaderService)); }, token: SoundcloudStrategy, providedIn: "root" });
