import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { Track } from '../../../../models/Track';
import { Album } from '../../../../models/Album';
import { queueId } from '../../queue-id';
export class BasePlayerSeekbar {
    constructor() {
        this.startPlaybackOnSeek = false;
        this.elapsedTime = '0:00';
        this.trackLength = 0;
        this.subscriptions = [];
        this.cache = {};
    }
    ngAfterViewInit() {
        // wait for animations to complete
        // TODO: refactor this to use events instead
        setTimeout(() => {
            this.setupCache();
            this.bindToPlayerStateEvents();
            this.setTrackLength();
            this.bindHammerEvents();
        }, 201);
    }
    ngOnDestroy() {
        this.stopSeekbarInterval();
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    }
    setupCache() {
        this.cache.outerTrackRect = this.outerTrack.nativeElement.getBoundingClientRect();
        this.cache.handleRect = this.progressHandle.nativeElement.getBoundingClientRect();
        this.cache.handlePercent = (this.cache.handleRect.width / this.cache.outerTrackRect.width) * 100 / 2;
        this.cache.handleEl = this.progressHandle.nativeElement;
        this.cache.progressTrackEl = this.progressTrack.nativeElement;
    }
    setElapsedTime(time = null) {
        if (time === null)
            time = this.player.getCurrentTime();
        if (time != null && isFinite(time)) {
            this.elapsedTime = this.duration.fromSeconds(time);
            this.elapsedTimeEl.nativeElement.textContent = this.elapsedTime;
        }
    }
    setTrackLength() {
        const duration = this.player.getDuration() || (this.track ? (this.track.duration / 1000) : null);
        if (!duration || duration === this.trackLength) {
            return;
        }
        this.trackLength = duration;
        this.trackLengthEl.nativeElement.textContent = this.duration.fromSeconds(this.trackLength);
    }
    seek(clickX) {
        if (!this.player.cued())
            return;
        this.stopSeekbarInterval();
        const rect = this.cache.outerTrackRect;
        const ratio = (clickX - rect.left) / rect.width, percent = ratio * 100;
        if (percent > 100)
            return;
        this.positionElapsedTrackAndHandle(percent);
        return ratio * this.player.getDuration();
    }
    startSeekbarInterval() {
        this.stopSeekbarInterval();
        this.zone.runOutsideAngular(() => {
            this.seekbarInterval = setInterval(() => {
                let percent = (this.player.getCurrentTime() / this.player.getDuration()) * 100;
                if (isNaN(percent))
                    percent = 0;
                if (percent <= 0)
                    return;
                this.positionElapsedTrackAndHandle(percent);
                this.setElapsedTime();
                this.setTrackLength();
            }, 50);
        });
    }
    stopSeekbarInterval() {
        if (!this.seekbarInterval)
            return;
        clearInterval(this.seekbarInterval);
        this.seekbarInterval = null;
    }
    positionElapsedTrackAndHandle(leftPercent) {
        let handleLeft = leftPercent - this.cache.handlePercent;
        if (leftPercent < 0)
            leftPercent = 0;
        if (handleLeft < 0)
            handleLeft = 0;
        this.cache.handleEl.style.left = handleLeft + '%';
        this.cache.progressTrackEl.style.width = leftPercent + '%';
    }
    bindHammerEvents() {
        let hammer, tap, pan;
        this.zone.runOutsideAngular(() => {
            hammer = new Hammer.Manager(this.el.nativeElement);
            tap = new Hammer.Tap();
            pan = new Hammer.Pan();
            hammer.add([tap, pan]);
        });
        hammer.on('tap panend', (e) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.player.cued()) {
                if (this.album || this.track) {
                    yield this.cueMedia();
                }
                else {
                    return;
                }
            }
            const time = this.seek(e.center.x);
            this.player.seekTo(time).then(() => {
                this.setElapsedTime(time);
                this.startSeekbarInterval();
                if (this.startPlaybackOnSeek) {
                    this.player.play();
                }
            });
        }));
        hammer.on('panstart', e => this.stopSeekbarInterval());
        hammer.on('panleft panright', e => this.seek(e.center.x));
    }
    cueMedia() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.album) {
                const id = queueId(this.album, 'allTracks');
                yield this.player.cueMediaItem(id, this.album.tracks, this.track);
            }
            else if (this.track) {
                if (!this.player.queue.has(this.track)) {
                    this.player.queue.prepend([this.track]);
                }
                yield this.player.cueTrack(this.track);
            }
        });
    }
    resetSeekbar() {
        this.positionElapsedTrackAndHandle(0);
        this.setElapsedTime(0);
    }
    getTrack() {
        return this.track;
    }
    /**
     * Update player controls on player state events.
     */
    bindToPlayerStateEvents() {
        if (this.player.state.playing) {
            this.startSeekbarInterval();
        }
        const sub = this.player.state.onChange$.subscribe(type => {
            switch (type) {
                case 'BUFFERING_STARTED':
                    this.stopSeekbarInterval();
                    break;
                case 'PLAYBACK_STARTED':
                    // if multiple seekbars are present on page,
                    // make sure they only respond to their own track
                    if (this.getTrack()) {
                        if (this.player.cued(this.getTrack())) {
                            this.startSeekbarInterval();
                        }
                        else {
                            this.stopSeekbarInterval();
                            this.resetSeekbar();
                        }
                    }
                    else {
                        this.startSeekbarInterval();
                    }
                    break;
                case 'PLAYBACK_PAUSED':
                    this.stopSeekbarInterval();
                    break;
                case 'PLAYBACK_STOPPED':
                    this.stopSeekbarInterval();
                    this.resetSeekbar();
                    break;
            }
        });
        this.subscriptions.push(sub);
    }
}
