/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-grid.component.scss.ngstyle";
import * as i1 from "./media-grid-item.component.scss.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "./media-grid.component";
var styles_MediaGridComponent = [i0.styles, i1.styles];
var RenderType_MediaGridComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_MediaGridComponent, data: {} });
export { RenderType_MediaGridComponent as RenderType_MediaGridComponent };
function View_MediaGridComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 10, "div", [["class", "nav-arrows"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 4, "button", [["mat-icon-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.slidePrevious() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i2.ɵdid(2, 180224, null, 0, i4.MatButton, [i2.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), i2.ɵpid(131072, i7.AsyncPipe, [i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(4, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "keyboard-arrow-left"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i2.ɵdid(5, 9158656, null, 0, i9.MatIcon, [i2.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i2.ɵeld(6, 0, null, null, 4, "button", [["mat-icon-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.slideNext() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i2.ɵdid(7, 180224, null, 0, i4.MatButton, [i2.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), i2.ɵpid(131072, i7.AsyncPipe, [i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(9, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "keyboard-arrow-right"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i2.ɵdid(10, 9158656, null, 0, i9.MatIcon, [i2.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !i2.ɵunv(_v, 2, 0, i2.ɵnov(_v, 3).transform(_co.hasPrevious$)); _ck(_v, 2, 0, currVal_2); var currVal_5 = "keyboard-arrow-left"; _ck(_v, 5, 0, currVal_5); var currVal_8 = !i2.ɵunv(_v, 7, 0, i2.ɵnov(_v, 8).transform(_co.hasNext$)); _ck(_v, 7, 0, currVal_8); var currVal_11 = "keyboard-arrow-right"; _ck(_v, 10, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = (i2.ɵnov(_v, 2).disabled || null); var currVal_1 = (i2.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i2.ɵnov(_v, 5).inline; var currVal_4 = (((i2.ɵnov(_v, 5).color !== "primary") && (i2.ɵnov(_v, 5).color !== "accent")) && (i2.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_6 = (i2.ɵnov(_v, 7).disabled || null); var currVal_7 = (i2.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_6, currVal_7); var currVal_9 = i2.ɵnov(_v, 10).inline; var currVal_10 = (((i2.ɵnov(_v, 10).color !== "primary") && (i2.ɵnov(_v, 10).color !== "accent")) && (i2.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_9, currVal_10); }); }
export function View_MediaGridComponent_0(_l) { return i2.ɵvid(0, [i2.ɵqud(671088640, 1, { gridContentEl: 0 }), (_l()(), i2.ɵeld(1, 0, null, null, 2, "div", [["class", "carousel-track"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, [[1, 0], ["gridContentEl", 1]], null, 1, "div", [["class", "media-grid-content"]], null, null, null, null, null)), i2.ɵncd(null, 0), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MediaGridComponent_1)), i2.ɵdid(5, 16384, null, 0, i7.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.carousel; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_MediaGridComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "media-grid", [], [[2, "carousel", null]], null, null, View_MediaGridComponent_0, RenderType_MediaGridComponent)), i2.ɵdid(1, 4243456, null, 0, i10.MediaGridComponent, [i2.ElementRef, i2.ChangeDetectorRef], null, null)], null, function (_ck, _v) { var currVal_0 = i2.ɵnov(_v, 1).carousel; _ck(_v, 0, 0, currVal_0); }); }
var MediaGridComponentNgFactory = i2.ɵccf("media-grid", i10.MediaGridComponent, View_MediaGridComponent_Host_0, { carousel: "carousel" }, {}, ["*"]);
export { MediaGridComponentNgFactory as MediaGridComponentNgFactory };
