import { Router } from '@angular/router';
import { catchError, mergeMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { NotificationsService } from './notifications.service';
import { CurrentUser } from '@common/auth/current-user';
import * as i0 from "@angular/core";
import * as i1 from "./notifications.service";
import * as i2 from "../../../common/auth/current-user";
import * as i3 from "@angular/router";
export class NotificationsResolverService {
    constructor(notifications, currentUser, router) {
        this.notifications = notifications;
        this.currentUser = currentUser;
        this.router = router;
    }
    resolve(route, state) {
        return this.notifications.all({ userId: this.currentUser.get('id') }).pipe(catchError(() => {
            this.router.navigate(['/']);
            return EMPTY;
        }), mergeMap(response => {
            if (response.pagination) {
                return of(response);
            }
            else {
                this.router.navigate(['/']);
                return EMPTY;
            }
        }));
    }
}
NotificationsResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationsResolverService_Factory() { return new NotificationsResolverService(i0.ɵɵinject(i1.NotificationsService), i0.ɵɵinject(i2.CurrentUser), i0.ɵɵinject(i3.Router)); }, token: NotificationsResolverService, providedIn: "root" });
