import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../core/http/app-http-client.service";
const BASE_URI = 'comment';
export class CommentsService {
    constructor(http) {
        this.http = http;
    }
    all(params) {
        return this.http.get(BASE_URI, params);
    }
    get(id) {
        return this.http.get(`${BASE_URI}/${id}`);
    }
    create(params) {
        return this.http.post(BASE_URI, params);
    }
    update(id, params) {
        return this.http.put(`${BASE_URI}/${id}`, params);
    }
    delete(params) {
        return this.http.delete(`${BASE_URI}/${params.ids}`);
    }
}
CommentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommentsService_Factory() { return new CommentsService(i0.ɵɵinject(i1.AppHttpClient)); }, token: CommentsService, providedIn: "root" });
