import { Tracks } from '../tracks/tracks.service';
import * as i0 from "@angular/core";
import * as i1 from "../tracks/tracks.service";
export class TrackPlays {
    constructor(tracks) {
        this.tracks = tracks;
        this.loggedTracks = [];
    }
    increment(track) {
        if (!track || this.hasBeenPlayed(track))
            return;
        this.loggedTracks.push(track.id);
        this.tracks.logPlay(track).subscribe(() => { }, () => { });
    }
    /**
     * Clear last track, if it matches specified track.
     * This will allow this track plays to be incremented again.
     */
    clearPlayedTrack(track) {
        if (!track)
            return;
        this.loggedTracks.splice(this.loggedTracks.indexOf(track.id), 1);
    }
    /**
     * Check if current user has already incremented plays of specified track.
     */
    hasBeenPlayed(track) {
        return this.loggedTracks.indexOf(track.id) > -1;
    }
}
TrackPlays.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TrackPlays_Factory() { return new TrackPlays(i0.ɵɵinject(i1.Tracks)); }, token: TrackPlays, providedIn: "root" });
