/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./artist-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/media-image/media-image.component.ngfactory";
import * as i3 from "../../shared/media-image/media-image.component";
import * as i4 from "../../../../common/core/config/settings.service";
import * as i5 from "../../player/playback-control-button/playback-control-button.component.ngfactory";
import * as i6 from "../../player/playback-control-button/playback-control-button.component";
import * as i7 from "../../player/player.service";
import * as i8 from "../artists-links-list/artists-links-list.component.ngfactory";
import * as i9 from "../artists-links-list/artists-links-list.component";
import * as i10 from "../../web-player-urls.service";
import * as i11 from "./artist-item.component";
var styles_ArtistItemComponent = [i0.styles];
var RenderType_ArtistItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ArtistItemComponent, data: {} });
export { RenderType_ArtistItemComponent as RenderType_ArtistItemComponent };
export function View_ArtistItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "img-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "media-image", [], null, null, null, i2.View_MediaImageComponent_0, i2.RenderType_MediaImageComponent)), i1.ɵdid(2, 573440, null, 0, i3.MediaImageComponent, [i4.Settings], { media: [0, "media"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "playback-control-button", [], null, [[null, "play"], [null, "pause"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("play" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } if (("pause" === en)) {
        var pd_1 = (_co.pause() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_PlaybackControlButtonComponent_0, i5.RenderType_PlaybackControlButtonComponent)), i1.ɵdid(4, 49152, null, 0, i6.PlaybackControlButtonComponent, [i7.Player], { playing: [0, "playing"] }, { play: "play", pause: "pause" }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "artists-links-list", [["class", "artist-name primary-text"]], null, null, null, i8.View_ArtistsLinksListComponent_0, i8.RenderType_ArtistsLinksListComponent)), i1.ɵdid(7, 573440, null, 0, i9.ArtistsLinksListComponent, [i10.WebPlayerUrls], { artists: [0, "artists"] }, null), i1.ɵpad(8, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.artist; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.playing(); _ck(_v, 4, 0, currVal_1); var currVal_2 = _ck(_v, 8, 0, _co.artist); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_ArtistItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "artist-item", [["class", "media-grid-item"]], [[2, "active", null]], null, null, View_ArtistItemComponent_0, RenderType_ArtistItemComponent)), i1.ɵdid(1, 49152, null, 0, i11.ArtistItemComponent, [i10.WebPlayerUrls, i7.Player], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).playing(); _ck(_v, 0, 0, currVal_0); }); }
var ArtistItemComponentNgFactory = i1.ɵccf("artist-item", i11.ArtistItemComponent, View_ArtistItemComponent_Host_0, { scrollContainer: "scrollContainer", artist: "artist" }, {}, []);
export { ArtistItemComponentNgFactory as ArtistItemComponentNgFactory };
