/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-image.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-lazyload-image";
import * as i3 from "./media-image.component";
import * as i4 from "../../../../common/core/config/settings.service";
var styles_MediaImageComponent = [i0.styles];
var RenderType_MediaImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MediaImageComponent, data: {} });
export { RenderType_MediaImageComponent as RenderType_MediaImageComponent };
export function View_MediaImageComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "img", [["draggable", "false"]], [[8, "alt", 0]], null, null, null, null)), i1.ɵdid(1, 1720320, null, 0, i2.LazyLoadImageDirective, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"], defaultImage: [1, "defaultImage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.src; var currVal_2 = _co.defaultImage(); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alt(); _ck(_v, 0, 0, currVal_0); }); }
export function View_MediaImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "media-image", [], null, null, null, View_MediaImageComponent_0, RenderType_MediaImageComponent)), i1.ɵdid(1, 573440, null, 0, i3.MediaImageComponent, [i4.Settings], null, null)], null, null); }
var MediaImageComponentNgFactory = i1.ɵccf("media-image", i3.MediaImageComponent, View_MediaImageComponent_Host_0, { media: "media", default: "default" }, {}, []);
export { MediaImageComponentNgFactory as MediaImageComponentNgFactory };
