import { Router } from '@angular/router';
import { Albums } from '../albums.service';
import { WebPlayerState } from '../../web-player-state.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../albums.service";
import * as i2 from "@angular/router";
import * as i3 from "../../web-player-state.service";
export class AlbumResolver {
    constructor(albums, router, state) {
        this.albums = albums;
        this.router = router;
        this.state = state;
    }
    resolve(route, state) {
        this.state.loading = true;
        const id = +route.paramMap.get('id');
        return this.albums.get(id, { simplified: route.data.simplified }).pipe(catchError(() => {
            this.state.loading = false;
            this.router.navigate(['/']);
            return EMPTY;
        }), mergeMap(response => {
            this.state.loading = false;
            if (response.album) {
                return of(response);
            }
            else {
                this.router.navigate(['/']);
                return EMPTY;
            }
        }));
    }
}
AlbumResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlbumResolver_Factory() { return new AlbumResolver(i0.ɵɵinject(i1.Albums), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.WebPlayerState)); }, token: AlbumResolver, providedIn: "root" });
