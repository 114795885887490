/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./playlist-item.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../../common/core/translations/translate.pipe";
import * as i5 from "../../../../common/core/translations/translations.service";
import * as i6 from "../../shared/media-image/media-image.component.ngfactory";
import * as i7 from "../../shared/media-image/media-image.component";
import * as i8 from "../../../../common/core/config/settings.service";
import * as i9 from "../../player/playback-control-button/playback-control-button.component.ngfactory";
import * as i10 from "../../player/playback-control-button/playback-control-button.component";
import * as i11 from "../../player/player.service";
import * as i12 from "./playlist-item.component";
import * as i13 from "../../web-player-urls.service";
var styles_PlaylistItemComponent = [i0.styles];
var RenderType_PlaylistItemComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PlaylistItemComponent, data: {} });
export { RenderType_PlaylistItemComponent as RenderType_PlaylistItemComponent };
function View_PlaylistItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["class", "hover-underline"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.urls.user(_v.context.ngIf); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "By")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 4).target; var currVal_2 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_4 = _v.context.ngIf.display_name; _ck(_v, 5, 0, currVal_4); }); }
export function View_PlaylistItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.TransPipe, [i5.Translations]), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "img-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "media-image", [], null, null, null, i6.View_MediaImageComponent_0, i6.RenderType_MediaImageComponent)), i1.ɵdid(3, 573440, null, 0, i7.MediaImageComponent, [i8.Settings], { media: [0, "media"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "playback-control-button", [], null, [[null, "play"], [null, "pause"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("play" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } if (("pause" === en)) {
        var pd_1 = (_co.pause() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_PlaybackControlButtonComponent_0, i9.RenderType_PlaybackControlButtonComponent)), i1.ɵdid(5, 49152, null, 0, i10.PlaybackControlButtonComponent, [i11.Player], { playing: [0, "playing"] }, { play: "play", pause: "pause" }), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "a", [["class", "playlist-name primary-text"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "creator-name secondary-text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaylistItemComponent_1)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.playlist; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.playing(); _ck(_v, 5, 0, currVal_1); var currVal_4 = _co.urls.playlist(_co.playlist); _ck(_v, 8, 0, currVal_4); var currVal_6 = _co.getCreator(); _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 8).target; var currVal_3 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_5 = _co.playlist.name; _ck(_v, 9, 0, currVal_5); }); }
export function View_PlaylistItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "playlist-item", [["class", "media-grid-item"]], [[2, "active", null]], null, null, View_PlaylistItemComponent_0, RenderType_PlaylistItemComponent)), i1.ɵdid(1, 49152, null, 0, i12.PlaylistItemComponent, [i13.WebPlayerUrls, i11.Player], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).playing(); _ck(_v, 0, 0, currVal_0); }); }
var PlaylistItemComponentNgFactory = i1.ɵccf("playlist-item", i12.PlaylistItemComponent, View_PlaylistItemComponent_Host_0, { scrollContainer: "scrollContainer", playlist: "playlist", creator: "creator" }, {}, []);
export { PlaylistItemComponentNgFactory as PlaylistItemComponentNgFactory };
