<div class="notifications-wrapper">
    <ng-container *ngIf="pagination$ | async as pagination">

        <div class="no-results-message" *ngIf="!pagination.data.length">
            <mat-icon class="icon" svgIcon="notifications"></mat-icon>
            <div class="text">
                <div class="main" trans>Hang tight!</div>
                <div class="secondary" trans>Notifications will start showing up here soon.</div>
            </div>
        </div>

        <ng-container *ngIf="pagination.data.length">
            <h1 trans>Notifications</h1>
            <ul>
                <li class="notification" *ngFor="let notification of pagination.data">
                    <ng-container [ngSwitch]="notification.type">
                        <ng-container *ngSwitchCase="notificationType.commentReply">
                            <media-image [media]="notification.data.user"></media-image>
                            <div class="middle-column">
                                <a class="username" [routerLink]="urls.user(notification.data.user)">{{notification.data.user.display_name}}</a>
                                <div class="content">
                                    <mat-icon svgIcon="comment"></mat-icon> <span class="quote">{{notification.data.comment_content}}</span>
                                </div>
                                <div class="track">
                                    {{ 'on' | trans }} <a [routerLink]="urls.track(notification.data.track)">{{notification.data.track.name}}</a>
                                </div>
                            </div>
                            <div class="right-column">{{notification.relative_created_at}}</div>
                        </ng-container>
                        <ng-container *ngSwitchCase="notificationType.newUpload">
                            <media-image [media]="notification.data.media" default="album"></media-image>
                            <div class="middle-column">
                                <a class="username" [routerLink]="urls.user(notification.data.artist)">{{notification.data.artist.name}}</a>
                                <div class="content">
                                    <mat-icon svgIcon="play-arrow"></mat-icon> <a class="quote" [routerLink]="mediaUrl(notification.data.media)">{{notification.data.media.name}}</a>
                                </div>
                                <div class="track" trans>New upload</div>
                            </div>
                            <div class="right-column">{{notification.relative_created_at}}</div>
                        </ng-container>
                    </ng-container>
                </li>
            </ul>
            <loading-indicator [isVisible]="loading$ | async" class="inline"></loading-indicator>
        </ng-container>
    </ng-container>
</div>