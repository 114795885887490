import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewCommentFormComponent} from './new-comment-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MediaImageModule} from '../../shared/media-image/media-image.module';
import {UiModule} from '@common/core/ui/ui.module';


@NgModule({
    declarations: [
        NewCommentFormComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MediaImageModule,
        UiModule,
    ],
    exports: [
        NewCommentFormComponent,
    ]
})
export class NewCommentFormModule {
}
